// import app from '@/core/firebase';
// import { auth } from "firebase/auth";
import axios from 'axios';
import router from '@/router';
import { backendUrl } from '@/config';
import useLocalStorage from '@/utils/useLocalStorage';
import useFingerprint from '@/utils/useFingerprint';
import { i18nRoute } from '@/utils/useTranslate';

useFingerprint().then(res => localStorage.setItem('fingerprint', res))


export default {

  // checking user

  async isUser({ commit, state }) {
    commit('SET_USER_LOADING', true)
    // const date = new Date()
    const LS = useLocalStorage()
    // const isPremium = true

    if (LS.userServeToken && !state.userData.dataServe) {
      await axios.get(`${backendUrl}/info/get?token=${LS.userServeToken}`)
        .then((response) => {
          commit('SET_USER_SERVE', response.data)
        })
        .catch((error) => {
          console.log(error.message);
        });
    }

    // if (isPremium) dispatch('moviePage/getWatchingData', null, { root: true })
    // await firebase.auth().onAuthStateChanged((user, error) => {
    //   console.log({ user });
    //   if (user) {
    // if (user && user.emailVerified) {
    // commit('SET_USER_FIREBASE', user)
    // user.getIdToken()
    //   .then((idToken) => {
    //     const data = JSON.stringify({
    //       "google_id_token": idToken,
    //       "google_client_id": user.uid
    //     });

    //     const config = {
    //       method: 'post',
    //       url: `${backendUrl}/user/authenticate`,
    //       headers: {
    //         'Content-Type': 'application/json'
    //       },
    //       data: data
    //     };

    //     axios(config)
    //       .then(function (response) {
    //         console.log(JSON.stringify(response.data));
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //   })
    // } else if (user && !user.emailVerified) {
    //   if (
    //     !LS.emailVerifiedTime ||
    //     LS.emailVerifiedTime != String(date.getDay()) ||
    //     LS.signInFormVisible
    //   ) {
    //     commit('SET_USER_EMAIL_VERIFIED', false)
    //     if (LS.emailVerifiedTime != String(date.getDay())) dispatch('resendVerificationLink')
    //   }
    // } else {
    //   if (error) commit('SET_USER_ERROR', error)
    //   if (!user) commit('SET_LOCAL_STORAGE_CLEAR')
    // }
    // })

    commit('SET_USER_LOADING', false)
  },

  async authenticateUser({ commit, dispatch }, data) {
    const configAuthenticate = {
      method: 'post',
      url: `${backendUrl}/user/authenticate`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    await axios(configAuthenticate)
      .then(({ data }) => {
        commit('SET_USER_SERVE', data)
        commit('SET_USER_SERVE_TOKEN', data.auth_token)
        dispatch('isUser')
        router.push(i18nRoute({ name: 'account' }));
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  // Login Email and Pasword

  async loginEmailAndPassword({ commit, dispatch }, { email, password }) {
    commit('SET_USER_LOADING', true)
    const LS = useLocalStorage()

    const dataLogin = JSON.stringify({
      "email": email,
      "password": password,
      "fingerprint": LS.userFingerprint
    });

    const configLogin = {
      method: 'post',
      url: `${backendUrl}/auth/login`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: dataLogin
    };

    await axios(configLogin)
      .then(({ data }) => {
        commit('SET_SESSION_EXP', data.result.sessionExpiresIn)
        dispatch('authenticateUser', dataLogin)
        commit('SET_SIGN_IN_FORM_VISIBLE', false)
      })
      .catch((error) => {
        console.log(error.response);
        commit('SET_USER_ERROR_SERVE', error.response.data)
      });
    commit('SET_USER_LOADING', false)
  },

  // update data user

  async userDataUpdate({ commit, dispatch }, payload) {
    const LS = useLocalStorage()
    await axios.get(`${backendUrl}/user/update?first_name=${payload.firstName}&last_name=${payload.lastName}&email=${payload.email}&url=${LS.userVanityUrl}&token=${LS.userServeToken}`)
      .then(({ data }) => {
        commit('SET_USER_SERVE', data)
        dispatch('isUser')
      })
      .catch(error => console.log('Main page content error - ', error));
  },

  async changePassword({ commit }, { passwordCurrent, passwordNew }) {
    const LS = useLocalStorage()

    // const dataLogin = JSON.stringify({
    //   "old_password": passwordCurrent,
    //   "password": passwordNew,
    //   "token": LS.userServeToken
    // });

    // const configChange = {
    //   method: 'post',
    //   url: `${backendUrl}/user/changePassword`,
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   data: dataLogin
    // };

    // await axios(configChange)
    //   .then(() => {

    //   })
    //   .catch((error) => {
    //     console.log('Change password error - ', error)
    //     commit('SET_CHANGE_PASSWORD_ERROR', error.response.data)
    //   });
    // commit('SET_USER_LOADING', false)
    await axios.post(`${backendUrl}/user/changePassword?old_password=${encodeURIComponent(passwordCurrent)}&password=${encodeURIComponent(passwordNew)}&token=${LS.userServeToken}`)
      .then(() => {
      })
      .catch(error => {
        console.log('Change password error - ', error)
        commit('SET_CHANGE_PASSWORD_ERROR', error.response.data)
      });
  },

  // Login from social media

  async loginFromSocialMedia({ commit }, payload) {
    commit('SET_USER_LOADING', true)
    commit('SET_USER_FIREBASE', payload)
    commit('SET_USER_LOADING', false)
  },

  // register email and password

  async emailPasswordRegister({ commit }, { email, password, phone }) {
    commit('SET_USER_LOADING', true)

    const LS = useLocalStorage()
    const dataLogin = JSON.stringify({
      "email": email,
      "password": password,
      "fingerprint": LS.userFingerprint,
      "phone": phone
    });

    const configLogin = {
      method: 'post',
      url: `${backendUrl}/user`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: dataLogin
    };

    await axios(configLogin)
      .then(() => {
        commit('SET_SIGN_UP_POPUP_SACCESS_VISIBLE', true)
      })
      .catch((error) => {
        console.log(error.response);
        commit('SET_USER_ERROR_SERVE', error.response.data)
      });

    // await axios.get(`${backendUrl}/user/create?email=${email}&password=${String(password)}&phone=${phone}&fingerprint=${LS.userFingerprint}`)
    //   .then(() => {
    //     commit('SET_SIGN_UP_POPUP_SACCESS_VISIBLE', true)
    //   })
    //   .catch((error) => {
    //     console.log(error.message);
    //   });
    commit('SET_SIGN_UP_FORM_VISIBLE', false)

    // await firebase.auth().createUserWithEmailAndPassword(email, password)
    //   .then((res) => {
    //     dispatch('isUser', res.user)
    //     console.log({ res });

    //     if (phone) {
    // const applicationVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' })
    // const provider = new firebase.auth.PhoneAuthProvider()

    // console.log({ provider });
    // res.user.linkWithPhoneNumber(phone, applicationVerifier)
    //   .then(confirmationResult => {
    //     const verificationCode = window.prompt('Please enter the verification ' +
    //       'code that was sent to your mobile device.')
    //     confirmationResult.confirm(verificationCode)
    //       .then(res => {
    //         console.log('res', res)
    //       })
    //       .catch(error => error)

    //   })
    //   .catch(error => error)

    // applicationVerifier.clear()

    // const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {})
    // console.log(recaptchaVerifier);
    // recaptchaVerifier.render().then((widgetId) => {
    //   console.log({ widgetId });
    //   window.recaptchaWidgetId = widgetId;
    // });
    // applicationVerifier.render().then((widgetId) => {
    //   console.log({ widgetId });
    //   window.applicationVerifierId = widgetId;
    // });
    // firebase.auth().currentUser.linkWithPhoneNumber(phone, window.applicationVerifierId)
    //   .then((confirmationResult) => {
    //     console.log({ confirmationResult });
    //     window.confirmationResult = confirmationResult
    //   })
    //   .catch(confirmationError => console.log({ confirmationError }))
    // // console.log({ confirmationResult });
    // const verificationCode = window.prompt('Please enter the verification ' +
    //   'code that was sent to your mobile device.')

    // window.confirmationResult.confirm(verificationCode)
    //   .then((res) => console.log({ res }))
    //   .catch(error => error)


    // applicationVerifier.clear()
    // }
    //   commit('SET_SIGN_UP_FORM_VISIBLE', false)
    // })
    // .catch((error) => {
    //   // Handle Errors here.
    //   commit('SET_USER_ERROR', error.code)
    //   // alert(error.message);
    //   console.log(error);
    // });

    commit('SET_USER_LOADING', false)
  },

  async refresh({ commit, dispatch }) {
    const LS = useLocalStorage()
    const data = JSON.stringify({
      fingerprint: LS.userFingerprint
    });

    const config = {
      method: 'post',
      url: `${backendUrl}/auth/refresh`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(({ data }) => {
        commit('SET_SESSION_EXP', data.result.sessionExpiresIn);
      })
      .catch((error) => {
        dispatch('logoutUser');
        console.log(error);
      });
  },


  async logoutUser({ commit }) {
    commit('SET_USER_LOADING', true)
    // auth(app)
    //   .then(() => {
    //     router.push('/');
    //   });
    router.push(i18nRoute({ name: 'home page' }));
    commit('SET_USER_LOGOUT');
    commit('SET_LOCAL_STORAGE_CLEAR')
    commit('SET_USER_LOADING', false)
  },

  async authResetPassword({ commit }, data) {
    commit('SET_USER_LOADING', true)
    const { email } = data

    const config = {
      method: 'post',
      url: `${backendUrl}/auth/send-reset-email/`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    await axios(config)
      .then(({ data }) => {
        console.log([email, data])
        commit('SET_RESET_PASSWORD', [email, data])
      })
      .catch(function (error) {
        console.log(error);
      });

    commit('SET_USER_LOADING', false)
  }

  // async loginEmailAndPassword({ commit, dispatch }, { email, password }) {
  //   commit('SET_USER_LOADING', true)
  //   await firebase.auth().signInWithEmailAndPassword(email, password)
  //     .then(({ user }) => {
  //       dispatch('isUser', user)
  //     })
  //     .catch((error) => {
  //       commit('SET_USER_ERROR', error)
  //       alert(error.message);
  //     })
  //   commit('SET_SIGN_IN_FORM_VISIBLE', false)
  //   commit('SET_USER_LOADING', false)
  // },

  // resend verification link

  // async resendVerificationLink() {
  //   await firebase.auth().currentUser.sendEmailVerification()
  //     .then(function () {
  //       // Verification email sent.
  //     })
  //     .catch(function (error) {
  //       // Error occurred. Inspect error.code.
  //       console.log({ error });
  //     });
  // },

  // initCaptcha() {
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
  //     size: 'invisible',
  //   });
  //   window.recaptchaVerifier.render().then((widgetId) => {
  //     window.recaptchaWidgetId = widgetId;
  //   });
  // },



  // async emailLogin({ commit, dispatch }, { email, password }) {
  //   const { user } = await firebase.auth().signInWithEmailAndPassword(email, password);
  //   console.log({ user });
  //   if (user.emailVerified) {
  //     commit('setUserLogin', user);
  //     commit('setSignInFormVisible', false);
  //     await dispatch('saveCustomer', { password });
  //     router.push('account');
  //   } else {
  //     await firebase.auth().signOut();
  //   }
  //   return user;
  // },

  // async resendVerificationLink(_, user) {
  //   return user.sendEmailVerification();
  // },

  // initCaptcha({ dispatch }) {
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
  //     size: 'invisible',
  //     callback: () => {
  //       dispatch('phoneLogin');
  //     },
  //   });
  //   window.recaptchaVerifier.render().then((widgetId) => {
  //     window.recaptchaWidgetId = widgetId;
  //   });
  // },

  // phoneLogin(_, phoneNumber) {
  //   const appVerifier = window.recaptchaVerifier;
  //   firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
  //     .then((confirmationResult) => {
  //       window.confirmationResult = confirmationResult;
  //     }).catch((error) => {
  //       console.log(error);
  //     });
  // },

  // async phoneOTPVerify(_, { code, getCredential }) {
  //   if (getCredential) {
  //     return firebase.auth.PhoneAuthProvider.credential(
  //       window.confirmationResult.verificationId,
  //       code,
  //     );
  //   }
  //   return window.confirmationResult.confirm(code);
  // },


  // async saveCustomer({ commit }, { password }) {
  //   const token = await firebase.auth().currentUser.getIdToken();
  //   const fpPromise = FingerprintJS.load()
  //   const fp = await fpPromise
  //   const { visitorId } = await fp.get()
  //   console.log({ visitorId });
  //   commit('setFingerprint', visitorId)
  //   localStorage.setItem('fingerprint', visitorId)

  //   const result = await axios.get(`${backendUrl}/user/create`, {
  //     params: {
  //       token,
  //       ...(password && { password }),
  //       fingerprint: visitorId
  //     }
  //   });
  //   const { data: { sessionExpiresIn } } = result;
  //   commit('storeSessionExp', sessionExpiresIn);
  //   return result;
  // },

  // async emailRegister({ dispatch }, { email, password }) {
  //   const res = await firebase.auth().createUserWithEmailAndPassword(email, password);
  //   await res.user.sendEmailVerification();
  //   await dispatch('saveCustomer', { password });
  //   await firebase.auth().signOut();
  // },

  // async mergeEmailPhoneRegister({ dispatch }, {
  //   credential,
  //   email,
  //   password,
  // }) {
  //   const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);
  //   await firebase.auth().currentUser.linkWithCredential(credential);
  //   await user.sendEmailVerification();
  //   await dispatch('saveCustomer', { password });
  //   await firebase.auth().signOut();
  // },

  // sendResetEmail(_, email) {
  //   return firebase.auth().sendPasswordResetEmail(email);
  // },

  // async refresh({ commit, dispatch, state }) {
  //   try {
  //     const { data: { result } } = await axios.post(`${backendUrl}/auth/refresh`, {
  //       fingerprint: state.userData.fingerprint,
  //     });
  //     console.log('Session', result);
  //     commit('SET_SESSION_EXP', result.sessionExpiresIn);

  //     return result;
  //   } catch (e) {
  //     dispatch('logout');
  //     return e;
  //   }
  // },
};
