import axios from 'axios';
import { backendUrl } from '@/config';

export default {
  async getFaqList({ commit }) {
    const { data: { result } } = await axios.get(`${backendUrl}/content/faq`);

    commit('setFaqList', result);
  },
};
