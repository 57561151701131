<template>
  <header v-if="!loading" class="header">
    <div class="header__container">
      <div v-if="!userData?.premium_member" class="ad-content__container">
        <div class="ad-content__section">
          <BunnerAds
            class="small"
            :ads="ads.top" />
        </div>
      </div>
      <nav class="header__navbar">
        <div class="navbar">
          <div class="navbar__links">
            <router-link
              v-if="!device.isMobile"
              :to="i18nRoute({ name: 'home page' })"
              class="navbar__link header__brand">
              <img class="header__brand-img " src="~@/assets/img/logo.svg">
            </router-link>
            <router-link class="navbar__link-menu" :to="i18nRoute({ name: 'home page' })">{{
              $t('headerMain.linkMenu1')
            }}</router-link>
            <router-link class="navbar__link-menu" :to="i18nRoute({ name: 'media page', params: { page: 'movies' } })">{{
              $t('headerMain.linkMenu2')
            }}</router-link>
            <router-link class="navbar__link-menu" :to="i18nRoute({ name: 'media page', params: { page: 'series' } })">{{
              $t('headerMain.linkMenu3')
            }}</router-link>
            <router-link class="navbar__link-menu"
              :to="i18nRoute({ name: 'feed page', params: { slug: 'new-on-mahatat' } })">{{
                $t('headerMain.linkMenu4')
              }}</router-link>
          </div>
          <div class="navbar__group">

            <!-- Search link component -->
            <HeaderSearchLink v-if="!device.isMobile" />
            <!-- end Search link component -->

            <HeaderUserDropdown v-if="isAuth && !device.isMobile" />

            <!-- Buttons group -->
            <div class="button__group" v-if="!isAuth">
              <UIButton
                v-if="!device.isMobile"
                @click="showSignIn()"
                class="btn__inline">
                {{ $t('form.signIn') }}
              </UIButton>
              <UIButton
                v-if="!device.isMobile"
                @click="showSignUp()">
                {{ $t('form.signUp') }}
              </UIButton>
            </div>

            <div v-if="device.isMobile" class="user__group">
              <router-link
                v-if="device.isMobile"
                :to="i18nRoute({ name: 'home page' })"
                class="navbar__link header__brand">
                <img class="header__brand-img--mobile " src="~@/assets/img/logo.svg">
              </router-link>
              <!-- User profile -->
              <HeaderUserDropdown v-if="isAuth" />
              <!-- end User profile -->
            </div>

            <!-- Languges -->
            <HeaderLanguages v-if="!device.isMobile" />
            <!-- end Languges -->
          </div>
        </div>

      </nav>
    </div>
  </header>
  <HeaderNavbarMobile v-if="device.isMobile" />

  <!-- Profiles modal -->
  <ProfilesModalAll />
  <!-- end Profiles modal -->

  <!-- Auth modals signIn and signUp -->
  <UIModal
    v-if="signInPopupVisible"
    @click="handleModal"
    :modalUser="true">
    <AuthSignIn @clickSignUp="handleClickSignUp" />
  </UIModal>
  <UIModal
    v-if="signUpPopupVisible"
    @click="handleModal"
    :modalUser="true">
    <AuthSignUp @clickSignIn="handleClickSignIn" />
  </UIModal>
  <!-- Auth modals verified -->
  <UIModal
    v-if="!AuthEmailVerifiedPopupVisible"
    @click="handleModal"
    :modalUser="true">
    <AuthEmailVerification>
      <template v-slot:title>{{ $t('verification-link-title') }}</template>
      <template v-slot:desc>
        {{ $t('verification-link-desc') }}
        <div class="link">
          <a
            class="sign-in-link"
            @click="handleClickSignIn">
            {{ $t('form.signIn') }}
          </a>
        </div>
      </template>
    </AuthEmailVerification>
  </UIModal>
  <!-- end auth modals -->
  <!-- Auth modals saccess signup -->
  <UIModal
    v-if="signUpSaccessPopupVisible"
    @click="handleModal"
    :modalUser="true">
    <AuthEmailVerification>
      <template v-slot:title>{{ $t('signupModals.saccessPopupMainText') }}</template>
      <template v-slot:desc>
        {{ $t('signupModals.saccessPopupMainSubText') }}
        <div class="link">
          <a
            class="sign-in-link"
            @click="handleClickSignIn">
            {{ $t('form.signIn') }}
          </a>
        </div>
      </template>
    </AuthEmailVerification>
  </UIModal>
  <!-- end Auth modals saccess signup -->
  <UIModal
    v-if="modalWatchlistVisible"
    @click="handleModal"
    :modalUser="true">
    <div class="modal-watchlist">
      <div class="circle-icon">
        <font-awesome-icon icon="exclamation" />
      </div>

      <p>{{ $t('moviePage.modalWatchlist') }}</p>
      <div class="link">
        <a
          class="sign-in-link"
          @click="handleClickSignIn">
          {{ $t('form.signIn') }}
        </a>
      </div>
    </div>
  </UIModal>
  <UIModal
    v-if="resetPasswordVisible"
    @click="handleModal"
    :modalUser="true">
    <AuthResetPassword />
  </UIModal>
  <UIModal
    v-if="checkEmailVisible"
    @click="handleModal"
    :modalUser="true">
    <div class="modal-watchlist">
      <div class="circle-icon">
        <font-awesome-icon icon="envelope" />
      </div>
      <UIModalHeader htitle="check-email-title" hdesc="check-email-desc" />

      <div class="auth-sign__button">
        <UIButton
          class="btn__border"
          @click="handleOpenEmailWindow">
          {{ $t('open-email') }}
        </UIButton>
      </div>
    </div>
  </UIModal>
  <UIModal
    v-if="createNewPasswordVisible"
    @click="handleOpenEmailWindow"
    :modalUser="true">
    <AuthCreateNewPassword />
  </UIModal>

  <!-- Mobile popup -->
  <MobilePopup v-if="isMobilePopup" />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex';
import AuthSignUp from '@/components/auth/AuthSignUp.vue';
import AuthSignIn from '@/components/auth/AuthSignIn.vue';
import UIButton from '@/components/ui/UIButton.vue';
import UIModal from '@/components/ui/UIModal.vue';
import HeaderUserDropdown from '@/components/header/HeaderUserDropdown.vue';
import HeaderSearchLink from '@/components//header/HeaderSearchLink.vue';
import HeaderLanguages from '@/components//header/HeaderLanguages.vue';
import ProfilesModalAll from '../profiles/ProfilesModalAll.vue';
import AuthEmailVerification from '@/components/auth/AuthEmailVerification.vue';
import AuthResetPassword from '@/components/auth/AuthResetPassword.vue';
import AuthCreateNewPassword from '@/components/auth/AuthCreateNewPassword.vue';
import HeaderNavbarMobile from './HeaderNavbarMobile.vue';
import MobilePopup from '@/components/layouts/MobilePopup.vue';
import UIModalHeader from '../ui/UIModalHeader.vue';
import BunnerAds from '@/components/bunners/BunnerAds.vue';
import { i18nRoute } from '@/utils/useTranslate';

const store = useStore()
const isAuth = computed(() => store.state.auth.userData.isAuth)
const device = computed(() => store.state.device)
const loading = computed(() => store.state.loading)
const userData = computed(() => store.state.auth.userData.dataServe)
const signUpPopupVisible = computed(() => store.state.auth.signUpFormVisible)
const signInPopupVisible = computed(() => store.state.auth.signInFormVisible)
const resetPasswordVisible = computed(() => store.state.auth.resetPasswordVisible)
const checkEmailVisible = computed(() => store.state.auth.checkEmailVisible)
const createNewPasswordVisible = computed(() => store.state.auth.createNewPasswordVisible)
const signUpSaccessPopupVisible = computed(() => store.state.auth.signUpSaccessPopupVisible)
const AuthEmailVerifiedPopupVisible = computed(() => store.state.auth.userData.isVerified)
const modalWatchlistVisible = computed(() => store.state.watchlist.modalWatchlistVisible)
const isMobilePopup = computed(() => store.state.isMobilePopup)
const ads = {
  top: {
    slotId: "ros_top",
    path: "/132205775/mahatat_ros_top",
    sizes: [[728, 90], [970, 90], [320, 50], [300, 100], [320, 100], [300, 50]],
    mapping: {
      0: [],
      360: [[320, 50], [320, 100], [300, 100]],
      1024: [[728, 90], [970, 90]]
    }
  }
}


const showSignUp = () => store.commit('auth/SET_SIGN_UP_FORM_VISIBLE', true)
const showSignIn = () => store.commit('auth/SET_SIGN_IN_FORM_VISIBLE', true)
// const showCreateNewPassword = () => store.commit('auth/SET_CREATE_NEW_PASSWORD_VISIBLE', true)
const handleModal = () => {
  store.commit('auth/SET_SIGN_IN_FORM_VISIBLE', false)
  store.commit('auth/SET_SIGN_UP_FORM_VISIBLE', false)
  store.commit('auth/SET_USER_EMAIL_VERIFIED', true)
  store.commit('auth/SET_SIGN_UP_POPUP_SACCESS_VISIBLE', false)
  store.commit('auth/SET_RESET_PASSWORD_VISIBLE', false)
  store.commit('auth/SET_CHECK_EMAIL_VISIBLE', false)
  store.commit('auth/SET_CREATE_NEW_PASSWORD_VISIBLE', false)
  store.commit('watchlist/SET_MODAL_WATCHLIST_VISIBLE', false)
}

const handleClickSignUp = () => {
  handleModal()
  showSignUp()
}

const handleClickSignIn = () => {
  handleModal()
  showSignIn()
}

// const handleOpenEmail = () => {
//   handleModal()
//   showCreateNewPassword()
// }

const handleOpenEmailWindow = async () => {
  const email = "адреса@example.com";
  const subject = "Тема листа";

  const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  window.location.href = mailtoUrl;
}

</script>

<style lang="scss" scoped>
.header {
  background-color: $header-bg;
  width: 100%;
  height: 226px;
  top: -138px;
  position: sticky;
  z-index: 9;

  @include media-breakpoint-down(xs) {
    background-color: #000;
    height: 181px;
  }

  &__brand {

    @include media-breakpoint-down(xs) {
      margin: 0;
    }

    &-img {
      max-width: rem(190);
    }

    &-img--mobile {
      max-height: rem(30);
    }
  }

  &__navbar {
    height: 88px;
    left: 0;
    right: 0;
    transform: translateY(0);
    transition: transform 1s ease 0s;
    z-index: 100;
    position: sticky;
    position: -webkit-sticky;
    top: 0;

    @include media-breakpoint-down(xs) {
      height: 45px;
    }
  }
}

.ad-content {

  // .ad-content__container

  &__container {
    min-height: 138px;
    clip-path: inset(0);
  }

  // .ad-content__section

  &__section {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 138px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.navbar {
  height: rem(96);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(xs) {
    height: rem(44);
    justify-content: center;
  }

  &__links {
    display: flex;
    align-items: center;
    column-gap: rem(32);
  }

  &__link-menu {
    font-weight: 600;
    line-height: math.div(24, 16);

    &:hover {
      color: $primary-color;
      text-decoration: underline;

    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__group {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      justify-content: center;
      width: 100%;
    }

    .button__group {
      margin-right: rem(30);

      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }
    }

    .user__group {
      display: flex;
      align-items: center;
      column-gap: rem(20);
    }
  }

  &__mobile-bottom {
    background-color: #000;
    bottom: 0;
    height: rem(70);
    width: 100%;
    position: fixed;
    z-index: 9;

    display: flex;
    align-items: center;
    justify-content: space-around;

  }

  &-mobile__item-link {
    color: $light-gray-color !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
      color: $light-gray-color !important;
      // padding: rem(8);
    }

    span {
      font-size: rem(10);
      line-height: math.div(15, 10);
    }
  }

  .button__group {
    button {
      font-size: rem(16);
      line-height: math.div(20, 16);
    }
  }
}

.sign-in-link {
  color: $primary-color;
  cursor: pointer;
  text-decoration: underline;
}

.modal-watchlist {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;


  p {
    margin-bottom: rem(15);
  }

  .circle-icon {
    border: rem(2) solid $primary-color;
    border-radius: 50%;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(35);
    height: rem(70);
    width: rem(70);
    margin-bottom: rem(30);
  }

  .sign-in-link {
    color: $primary-color;
    cursor: pointer;
    text-decoration: underline;
  }
}

.btn__border {
  width: 100%;
}

.auth-sign__button {
  width: 100%;
}
</style>