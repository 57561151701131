import store from '@/store';

export default (actionFn) => async (context, payload) => {
  const isSessionExpired = +store.state.auth.userData.sessionExpIn - Math.ceil(Date.now() / 1000) < 3;

  if (isSessionExpired) {
    try {
      await store.dispatch('auth/refresh');
    } catch {
      store.dispatch('auth/logout');
      return null;
    }
  }

  return actionFn.call(this, context, payload);
};
