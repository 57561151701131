<template>
  <div class="preloader">
    <img
      src="~@/assets/img/logo.svg"
      class="preloader-image">
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>