<template>
  <div class="auth-sign-header">
    <div class="auth-sign-header__title">{{ t(htitle) }}</div>
    <p class="auth-sign-header__desc">{{ t(hdesc) }}</p>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  props: {
    htitle: {
      type: String,
      default: 'Title',
      require: true
    },
    hdesc: {
      type: String,
      default: '',
      require: false
    }
  },

}
</script>

<style lang="scss" scoped>
.auth-sign-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 rem(30);

  margin-bottom: rem(32);

  &__title {
    color: $base-color-font;
    font-size: rem(36);
    font-weight: 700;
    line-height: math.div(40, 36);
    margin-bottom: rem(10);
  }

  &__desc {
    color: $light-gray-color;
    font-size: rem(16);
    font-weight: 400;
    line-height: math.div(21, 16);
    max-width: rem(420);
  }
}
</style>