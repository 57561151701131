<template>
	<div class="auth-sign__logo">
		<img src="@/assets/img/logo.svg">
	</div>
</template>

<script>

</script>

<style lang="scss" scoped>
.auth-sign__logo {
	display: flex;
	justify-content: center;
	height: rem(30);
	margin-bottom: rem(32);
}
</style>