<template>
  <div class="user-dropdown">
    <div
      class="user-dropdown__btn user-data"
      @click="toggleDropdown">
      <div class="user-data__image">
        <img
          v-if="userDataFirebase?.photoURL || userDataServe?.avatar"
          :src="userDataFirebase?.photoURL || userDataServe?.avatar"
          alt="">
        <div
          v-else
          class="user-data__icon">
          <font-awesome-icon icon="user" />
        </div>
      </div>
      <font-awesome-icon icon="angle-down" />
    </div>
    <ul
      class="user-dropdown__menu"
      v-if="stateDropdownMenu">
      <li class="user-dropdown__item user-data__item">
        <span class="user-data__name">{{
          userDataFirebase?.displayName || (userDataServe?.first_name + " " + userDataServe?.last_name)
        }}</span>
        <span class="user-data__email">{{ userDataFirebase?.email || userDataServe?.email }}</span>
      </li>
      <li class="user-dropdown__item divider"></li>
      <li class="user-dropdown__item user-dropdown__link">
        <router-link :to="i18nRoute({ name: 'profile' })">{{ $t('headerMain.Profile') }}</router-link>
      </li>
      <li class="user-dropdown__item user-dropdown__link">
        <router-link :to="i18nRoute({ name: 'watchlist page' })">{{ $t('headerMain.Watchlist') }}</router-link>
      </li>
      <li class="user-dropdown__item user-dropdown__link">
        <router-link :to="i18nRoute({ name: 'subscription' })">{{ $t('headerMain.Packages') }}</router-link>
      </li>
      <li class="user-dropdown__item user-dropdown__link">
        <router-link :to="i18nRoute({ name: 'support page' })">{{ $t('headerMain.Support') }}</router-link>
      </li>
      <li class="user-dropdown__item divider"></li>
      <li class="user-dropdown__item user-dropdown__link">
        <span @click="logOut()">{{ $t('headerMain.LogOut') }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { i18nRoute } from '@/utils/useTranslate';


const store = useStore()
const userDataFirebase = computed(() => store.state.auth.userData.dataFirebase)
const userDataServe = computed(() => store.state.auth.userData.dataServe)
const stateDropdownMenu = ref(false)
const logOut = () => {
  store.dispatch('auth/logoutUser')
  stateDropdownMenu.value = false;
}
const toggleDropdown = () => {
  stateDropdownMenu.value = !stateDropdownMenu.value
}

window.addEventListener('click', function (e) {
  if (e.target.closest('.user-dropdown__btn') === null) {
    stateDropdownMenu.value = false
  }
});

</script>

<style lang="scss" scoped>
.user-data {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__name {
    color: $base-color-font;
    font-size: rem(16);
    font-weight: 600;
    line-height: math.div(20, 16);
  }

  &__email {
    color: $light-gray-color;
    font-size: rem(16);
    font-weight: 400;
    line-height: math.div(19, 16);
  }

  &__image {
    height: rem(45);
    width: rem(45);
    border-radius: 50%;
    overflow: hidden;
    margin-right: rem(16);

    @include media-breakpoint-down(xs) {
      height: rem(26);
      width: rem(26);
      margin-right: 0;
    }

    img {
      width: rem(45);
      height: rem(45);

      @include media-breakpoint-down(xs) {
        height: rem(24);
        width: rem(24);
      }
    }
  }

  &__icon {
    height: rem(45);
    width: rem(45);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-gray-color;

    @include media-breakpoint-down(xs) {
      height: rem(26);
      width: rem(26);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
  }
}

.user-dropdown {
  position: relative;
  margin-right: rem(30);
  margin-left: rem(30);

  @include media-breakpoint-down(xs) {
    margin: 0;
    position: absolute;
    right: rem(17);
  }

  &__btn {
    @include media-breakpoint-down(xs) {
      .fa-angle-down {
        display: none;
      }
    }
  }

  &__menu {
    background-color: $dropdown-bg;
    border-radius: rem(5);
    min-width: rem(262);

    position: absolute;
    top: rem(69);
    right: 0;
    z-index: 99;

    @include media-breakpoint-down(xs) {
      top: rem(50);
    }
  }

  &__item {
    padding: rem(15);
    cursor: pointer;


    &.divider {
      padding: 0;
    }

    a,
    span {
      display: block;
    }
  }

  &__link {
    color: $light-gray-color;
    font-size: rem(16);
    font-weight: 400;
    line-height: math.div(19, 16);

    &:hover {
      color: $base-color-font;
    }
  }
}

[dir="rtl"] {
  .user-data {
    &__image {
      margin-right: 0;
      margin-left: rem(16);
    }
  }
}
</style>
