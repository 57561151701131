export default {
  SET_DIRECTION: (state, payload) => {
    state.direction = payload;
    document.getElementsByTagName('html')[0].setAttribute('dir', payload);
  },
  SET_LANGUAGE: (state, payload) => {
    state.language = payload;
    document.getElementsByTagName('html')[0].setAttribute('lang', payload);
    localStorage.setItem('userLocale', payload)

  },
  SET_DEVICE: (state, payload) => state.device = payload,
  SET_LOADING: (state, payload) => state.loading = payload,
  SET_PRELOAD: (state, payload) => state.preload = payload,
  // setAppLoadingState(state, value) {
  //   state.isAppLoading = value;
  // },
  SET_MOBILE_MENU_STATE: (state, value) => state.isMobileMenuOpened = value,
  SET_MOBILE_POPUP_VISIBLE: (state, value) => state.isMobilePopup = value,
  // setSimpleLayout(state, value) {
  //   state.isLayoutSimple = value;
  // },
  // setFlatLayout(state, value) {
  //   state.isLayoutFlat = value;
  // },
};
