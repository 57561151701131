import axios from 'axios';
import { backendUrl, videoCDNUrl } from '@/config';
import useLocalStorage from '@/utils/useLocalStorage.js';
import app from '@/core/firebase';
import { getDatabase, ref, set, get, child } from "firebase/database";

export default {
  async loadMovieData({ commit, rootState }, payload) {
    commit('SET_PRELOAD', true, { root: true })
    commit('SET_LOADING_MD', true);
    const LS = useLocalStorage()
    const isAuth = rootState.auth.userData.isAuth
    const isPremium = rootState.auth.userData.dataServe?.isPremium

    if (isAuth && isPremium) {
      const dbRef = ref(getDatabase(app))
      await get(child(dbRef, `watching/${LS.userServeId}/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userWatchingList = snapshot.val()
            const asArray = Object.entries(userWatchingList);
            const watchingListFiltered = asArray.filter(([key, value]) => {
              if (value.contentID === payload) {
                return [key, value]
              }
            })
            // console.log('2. Get watching movie', watchingListFiltered);
            commit('SET_WATCHING_DATA', watchingListFiltered);
          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        });
    }

    await axios.get(`${backendUrl}/premium_content/get?slug=${payload}&user_id=${LS?.userServeId ? LS?.userServeId : ''}&locale=${rootState.language}`)
      .then(({ data }) => {
        // console.log('3. Load movie data', data);
        commit('SET_MOVIE_DATA', data);
      })
      .catch(error => console.log('Load movie data error - ', error));
    commit('SET_LOADING_MD', false);
    commit('SET_PRELOAD', false, { root: true })
  },

  async loadVideoCurrent({ commit, state, rootState }, payload) {
    commit('SET_PRELOAD', true, { root: true })
    commit('SET_LOADING_VCP', true);

    console.log({ state });
    await axios.get(`${videoCDNUrl}/media/${payload?.media_id}?locale=${rootState.language}`)
      .then(({ data }) => {
        const newSources = { 'sources': [], ...payload };
        data.playlist[0].sources.map(item => {
          newSources.sources.push({
            src: item.file,
            type: item.type,
          });
        });
        commit('SET_VIDEO_CURRENT_PLAYLIST', newSources);
      })
      .catch(error => console.log('Get video current playlist error - ', error));
    commit('SET_LOADING_VCP', false);
    commit('SET_PRELOAD', false, { root: true })
  },

  toggleModalVideoPlayer({ commit }, payload) {
    commit('SET_TOGGLE_MODAL_VIDEO_PLAYER', payload);
  },

  async setVideoWatchingData({ commit }, payload) {
    commit('SET_WATCHING_LOADING', true)
    const db = getDatabase(app);
    const LS = useLocalStorage()

    await set(ref(db, `watching/${LS.userServeId}/${payload.id}`), payload)
      .then(() => {
        // console.log('1. Set new movie');
        // dispatch('loadMovieData', payload.contentID)
      })
      .catch((error) => {
        console.log('Set firebase error - ', error);
      });

    commit('SET_WATCHING_LOADING', false)
  },
}