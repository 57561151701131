<template>
  <div class="card-form">
    <UIModalLogo />
    <UIModalHeader htitle="sign-in" hdesc="join-and-get-access" />
    <div class="card-form__body">
      <AuthSignInEmailForm v-if="isEmailLoginForm" />
      <!-- <AuthSingInPhoneForm v-else /> -->
      <!-- <div class="card-form__item-btn">
        <AuthGoogleLogin />
      </div>
      <div class="card-form__item-btn">
        <AuthFacebookLogin />
      </div>
      <div class="card-form__item-btn">
        <UIButton
          :class="[{ 'btn__green': !isEmailLoginForm }]"
          @click="toggleEmailAndPhoneLogin"
          style="width: 100%">
          <span v-if="isEmailLoginForm">{{ $t('signin-phone') }}</span>
          <span v-else>{{ $t('signin-email') }}</span>
        </UIButton>
      </div> -->
      <div class="card-form__item-link">
        {{ $t('no-account') }}
        <router-link to="#" @click="handleSignUp">
          {{ $t('form.signUp') }}
        </router-link>
      </div>
    </div>
    <div id="recaptcha-container"></div>
  </div>
</template>

<script setup>
// import { useStore } from 'vuex';
import { ref, defineEmits } from 'vue';
import UIModalLogo from '../ui/UIModalLogo.vue';
import UIModalHeader from '../ui/UIModalHeader.vue';
// import UIButton from '@/components/ui/UIButton.vue';
import AuthSignInEmailForm from './AuthSignInEmailForm.vue';
// import AuthSingInPhoneForm from './AuthSingInPhoneForm.vue';
// import AuthGoogleLogin from './AuthGoogleLogin.vue';
// import AuthFacebookLogin from './AuthFacebookLogin.vue';

// const store = useStore()?
const emit = defineEmits(['clickSignUp'])
const handleSignUp = () => emit('clickSignUp')
const isEmailLoginForm = ref(true)
// const toggleEmailAndPhoneLogin = () => {
//   if (isEmailLoginForm.value) {
//     isEmailLoginForm.value = false
//     store.dispatch('auth/initCaptcha')
//   } else {
//     isEmailLoginForm.value = true;
//   }
// }
</script>

<style lang="scss" scoped>
.card-form {
  &__item-btn {
    margin-bottom: rem(16);
  }

  &__item-link {
    color: $light-gray-color;
    text-align: center;
    margin: rem(32) auto 0;

    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}
</style>