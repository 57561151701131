import axios from 'axios';
import { backendUrl } from '@/config';
import useLocalStorage from '@/utils/useLocalStorage';
import app from '@/core/firebase';
import { getDatabase, ref, get, child } from "firebase/database";

// * state
const state = () => ({
  watchlistData: {
    loading: false,
    data: null,
    error: null,
    addDeleteError: false
  },
  modalWatchlistVisible: false,
  watching: {
    loading: false,
    data: {},
    error: null,
  }
})

// * mutations 

const mutations = {
  SET_WATCHLIST_DATA: (state, payload) => state.watchlistData.data = payload,
  SET_WATCHLIST_LOADING: (state, payload) => state.watchlistData.loading = payload,
  SET_ADD_DELETE_WATCHLIST_STATUS: (state, payload) => state.watchlistData.addDeleteError = payload,
  SET_MODAL_WATCHLIST_VISIBLE: (state, payload) => state.modalWatchlistVisible = payload,
  SET_WATCHING_DATA: (state, payload) => state.watching.data = payload,
  SET_WATCHING_LOADING: (state, payload) => state.watching.loading = payload,
  SET_WATCHING_ERROR: (state, payload) => state.watching.error = payload,
}

// * actions

const actions = {
  async loadWatchlistData({ commit, rootState }) {
    commit('SET_WATCHLIST_LOADING', true)
    const LS = useLocalStorage()
    const isPremium = rootState.auth.userData.dataServe?.isPremium
    const isAuth = rootState.auth.userData.isAuth

    if (isAuth && isPremium) {
      const dbRef = ref(getDatabase(app))
      await get(child(dbRef, `watching/${LS.userServeId}/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userWatchingList = snapshot.val()
            commit('SET_WATCHING_DATA', userWatchingList);
          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        });
    }

    await axios.get(`${backendUrl}/channel/feed?user_vanity_url=${LS.userVanityUrl
      }&vanity_url="watch_later"&normalize=true&no_cache=true`)
      .then(({ data }) => {
        commit('SET_WATCHLIST_DATA', data)
      })
      .catch(error => console.log('Watchlist page content error - ', error));
    commit('SET_WATCHLIST_LOADING', false)
  },

  async addWatchlist({ commit }, payload) {
    commit('SET_WATCHLIST_LOADING', true)
    const LS = useLocalStorage()
    await axios.post(`${backendUrl}/post/add?entity_id=${payload}&vanity_url="watch_later"&user_id=${LS.userServeId}&token=${LS.userServeToken}`)
      .then(() => {
        commit('SET_ADD_DELETE_WATCHLIST_STATUS', false)
      })
      .catch(error => {
        commit('SET_ADD_DELETE_WATCHLIST_STATUS', false)
        console.log('Watchlist add error - ', error)
      });
    commit('SET_WATCHLIST_LOADING', false)
  },

  async deleteItemWatchlist({ commit }, payload) {
    commit('SET_WATCHLIST_LOADING', true)
    const LS = useLocalStorage()
    await axios.get(`${backendUrl}/channel/delete?entity_id=${payload}&watch_later=1&user_id=${LS.userServeId}&token=${LS.userServeToken}`)
      .then(() => {
        commit('SET_ADD_DELETE_WATCHLIST_STATUS', false)
      })
      .catch(error => {
        commit('SET_ADD_DELETE_WATCHLIST_STATUS', true)
        console.log('Watchlist add error - ', error)
      });
    commit('SET_WATCHLIST_LOADING', false)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};