import { defaultDirection, defaultLanguage } from '@/config';



export default {
  preload: false,
  loading: false,
  direction: defaultDirection,
  language: defaultLanguage,
  isAppLoading: true,
  isMobileMenuOpened: false,
  isLayoutSimple: false,
  isLayoutFlat: false,
  device: null,
  isMobilePopup: false
};
