<template>
  <div class="form__control">
    <input
      :id="name"
      :type="type !== 'password' ? type : passwordType"
      :value="value"
      placeholder=" "
      @input="updateValue"
      @blur="onBlur" 
      :disabled="disabled"
      />
    <label :for="name">{{ $t(label) }}</label>
    <img v-if="passwordBtn" src="~@/assets/img/password-logo.svg" class="btn__password-show"
      @mousedown="showPassword" @mouseup="hidePassword" @touchstart="showPassword" @touchend="hidePassword" />
  </div>
  <div class="form__error">
    <small v-for="error in errors" :key="error.$uid">
      {{ error.$message }}
    </small>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';

const emit = defineEmits(['update:value', 'blur'])
defineProps({
  name: {
    type: String,
    require: true
  },
  label: {
    type: String,
    require: false
  },
  type: {
    type: String,
    require: true
  },
  value: {
    type: String,
    default: ''
  },
  passwordBtn: {
    type: Boolean,
    default: false
  },
  errors: {
    type: Array,
    require: false
  },
  disabled: {
    type: Boolean,
    require: false
  }
})
const passwordType = ref('password');
const showPassword = () => passwordType.value = 'text'
const hidePassword = () => passwordType.value = 'password'
const updateValue = (e) => {
  emit('update:value', e.target.value)
}
const onBlur = () => emit('blur')
</script>

<style lang="scss" scoped>

</style>