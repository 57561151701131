import { createRouter, createWebHistory, RouterView } from 'vue-router';
import store from '@/store';
import { isLocaleSupported, guessDefaultLocale, switchLanguage } from '@/utils/useTranslate'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [{
    path: "/:locale?",
    component: RouterView,
    props: true,
    beforeEnter: (to, _from, next) => {
      const paramLocale = to.params.locale
      console.log('beforeEnter', isLocaleSupported(paramLocale))
      if (!isLocaleSupported(paramLocale)) {
        return next(guessDefaultLocale())
      }
      switchLanguage(paramLocale)

      return next()
    },
    children: [
      {
        path: '',
        name: 'home page',
        component: () => import('@/views/HomePage.vue'),
      },
      {
        path: '/policy.html',
        redirect: { name: 'staticPage', params: { id: 'privacy-policy', locale: 'en' } }
      },
      {
        path: '/terms.html',
        redirect: { name: 'staticPage', params: { id: 'terms-and-conditions', locale: 'en' } }
      },
      {
        path: 'media/:page',
        name: 'media page',
        component: () => import('@/views/MediaPage.vue'),
      },
      {
        path: 'movie/:id',
        name: 'movie page',
        component: () => import('@/views/MoviePage.vue'),
      },
      {
        path: 'actor/:id',
        name: 'actor page',
        component: () => import('@/views/ActorPage.vue'),
      },
      {
        path: 'categories/',
        name: 'categories page',
        component: () => import('@/views/CategoriesPage.vue'),
      },
      {
        path: 'categories/:id',
        name: 'category page',
        component: () => import('@/views/CategoryPage.vue'),
      },
      {
        path: 'castcrew/',
        name: 'castcrew page',
        component: () => import('@/views/CastcrewPage.vue'),
      },
      {
        path: 'watchlist/',
        name: 'watchlist page',
        component: () => import('@/views/WatchlistPage.vue'),
      },
      {
        path: 'feed/:slug',
        name: 'feed page',
        component: () => import('@/views/FeedPage.vue'),
      },
      {
        path: 'search/',
        name: 'search page',
        component: () => import('@/views/SearchPage.vue'),
      },
      {
        path: 'other/:id',
        name: 'staticPage',
        component: () => import('@/views/StaticPage.vue'),
      },
      {
        path: 'support/',
        name: 'support page',
        component: () => import('@/views/SupportPage.vue')
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('@/modules/account/Account.vue'),
        meta: { requiresAuth: true },
        redirect: { name: 'home page' },
        children: [
          {
            path: 'profile',
            component: () => import('@/modules/account/profile/Profile.vue'),
            name: 'profile'
          },
          {
            path: 'settings',
            component: () => import('@/modules/account/settings/'),
            name: 'settings'
          },
          {
            path: 'subscription',
            component: () => import('@/modules/account/subscription/Subscription.vue'),
            name: 'subscription'
          },
          {
            path: 'subscription/sla/:service',
            component: () => import('@/modules/account/subscription/Subscription.vue'),
            props: true,
          },
          {
            path: 'subscription/payment',
            component: () => import('@/modules/account/subscription/payment/Payment.vue'),
            children: [
              {
                path: '',
                redirect: { name: 'creditcard' },
              },
              {
                path: 'creditcard',
                component: () => import('@/modules/account/subscription/payment/PaymentCreditCard.vue'),
                meta: { skipScroll: true },
                name: 'creditcard'
              },
              {
                path: 'sla',
                component: () => import('@/modules/account/subscription/payment/PaymentSla.vue'),
                meta: { skipScroll: true },
                name: 'sla'
              },
              {
                path: 'knet',
                component: () => import('@/modules/account/subscription/payment/PaymentKnet.vue'),
                meta: { skipScroll: true },
                name: 'knet'
              },
            ],
          },
          {
            path: 'subscription/payment/success',
            component: () => import('@/modules/account/subscription/payment/PaymentSuccess.vue'),
          },
        ],
      },
      {
        path: ':pathMatch(.*)*',
        name: '404 page',
        component: () => import('@/views/Page404.vue'),
      }
    ]
  }],
  scrollBehavior: (to, _, savedPosition) => {
    const skipScroll = to.matched.some((record) => record.meta.skipScroll);

    if (skipScroll) {
      return savedPosition;
    }

    return { left: 0, top: 0 };
  },
})

router.beforeEach(async (to, _, next) => {
  const isAuth = localStorage.getItem('isAuth');
  const needUser = to.matched.some((record) => record.meta.requiresAuth);
  const paramLocale = to.params.locale;

  if (!isLocaleSupported(paramLocale)) {
    next(guessDefaultLocale());
  } else {
    switchLanguage(paramLocale);
    store.commit('payment/setError', null);

    if (needUser && !isAuth) {
      next({
        path: '/',
        // save the location we were at to come back later
        query: { redirect: to.fullPath },
      });
      store.commit('auth/SET_SIGN_IN_FORM_VISIBLE', true);
    } else {
      next();
    }
  }
});

export default router
