import axios from 'axios';
import { backendUrl } from '@/config';

export default {
  async loadActorData({ rootState, commit }, payload) {
    commit('SET_PRELOAD', true, { root: true })
    commit('SET_ACTOR_LOADING', true)
    await axios.get(`${backendUrl}/castcrew/slug/${payload}?skip_cache=1&format=json&source=telly-web&locale=${rootState.language}&beta=true`)
      .then(({ data }) => {
        commit('SET_ACTOR_DATA', data.result);
      })
      .catch(error => {
        console.log('Load actor data error - ', error);
      })
    commit('SET_ACTOR_LOADING', false)
    commit('SET_PRELOAD', false, { root: true })
  }
}