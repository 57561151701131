<template>
  <UIModal v-if="isAuthenticated && isProfilesVisible">
    <UIModalLogo />
    <UIModalHeader htitle="Who is watching now?" />
    <div class="profiles">
      <div class="profiles__items">
        <div class="profiles__item">
          <div class="profiles__img"></div>
          <div class="profiles__name">Bruce</div>
        </div>
        <div class="profiles__item">
          <div class="profiles__img"></div>
          <div class="profiles__name">Bruce</div>
        </div>
        <div class="profiles__item">
          <div class="profiles__img"></div>
          <div class="profiles__name">Bruce</div>
        </div>
        <div class="profiles__item">
          <div class="profiles__img"></div>
          <div class="profiles__name">Bruce</div>
        </div>
      </div>
      <div class="profiles__link">
        <a class="link__primary" href="#">{{$t('edit-profiles')}}</a>
      </div>
    </div>

  </UIModal>
</template>

<script>
import { mapState } from 'vuex';
import UIModal from '../ui/UIModal.vue';
import UIModalLogo from '../ui/UIModalLogo.vue';
import UIModalHeader from '../ui/UIModalHeader.vue';

export default {
  data() {
    return {
      isProfilesVisible: false
    }
  },
  components: {
    UIModal,
    UIModalLogo,
    UIModalHeader
  },
  computed: {
    ...mapState('auth', ['isAuthenticated']),
  },
}
</script>

<style lang="scss" scoped>
.profiles {
  &__items {
    margin-bottom: rem(32);
    display: flex;
    align-items: center;
    column-gap: rem(20);
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  &__img {
    border-radius: 50%;
    background-color: $light-gray-color;
    height: rem(140);
    width: rem(140);
    margin-bottom: rem(10);
  }

  &__name {
    color: $base-color-font;
    font-size: rem(18);
    font-weight: 600;
    line-height: math.div(21, 18);
  }

  &__link {
    text-align: center;
  }
}
</style>
