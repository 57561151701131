<template>
	<div class="card-form">
		<UIModalLogo />
		<UIModalHeader htitle="reset-password" hdesc="reset-password-desc" />
		<div class="card-form__body">
			<form class="auth-sign-form">
				<div class="form__column">
					<UIInput
						name="fieldEmail"
						label="email-address"
						type="email"
						v-model:value="v$.emailAuth.$model"
						@blur="v$.emailAuth.$commit"
						:errors="v$.emailAuth.$errors" />
				</div>
			</form>
			<div class="auth-sign__button">
				<UIButton
					class="btn__border"
					:disabled="isSubmitDisabled"
					@click="handleResetPassword">
					{{ $t('submit') }}
				</UIButton>
			</div>
		</div>
		<div id="recaptcha-container"></div>
	</div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import UIModalLogo from '../ui/UIModalLogo.vue';
import UIModalHeader from '../ui/UIModalHeader.vue';
import { email, helpers, required } from '@vuelidate/validators';
import UIButton from '@/components/ui/UIButton.vue';
import UIInput from '@/components/ui/UIInput.vue';
// import { useToast } from 'vue-toast-notification';

const store = useStore()
const { t } = useI18n()
// const $toast = useToast()
const emailAuth = ref('')
const errorServe = computed(() => store.state.auth.userData.errorServe)

const rules = computed(() => ({
	emailAuth: {
		email: helpers.withMessage(t('enter-valid-email-address'), email),
		required: helpers.withMessage(t('field-required'), required),
	}
}))

const v$ = useVuelidate(rules,
	{ emailAuth },
	{ $rewardEarly: true })

// * Form processing
// * ------------------------------------
const isSubmitDisabled = ref(true)

watchEffect(() => {
	if (!v$.value.$error && v$.value.$dirty) {
		isSubmitDisabled.value = false
	} else {
		isSubmitDisabled.value = true
	}
})

const handleResetPassword = async () => {
	if (v$.value.$invalid) {
		v$.value.$validate()
		return;
	}

	const payload = {
		email: emailAuth.value,
	};
	await store.dispatch('auth/authResetPassword', payload)
		.then(() => {
			store.commit('auth/SET_RESET_PASSWORD_VISIBLE', false)
			store.commit('auth/SET_CHECK_EMAIL_VISIBLE', true)
		})
		.catch(error => {
			console.log({ errorServe });
			console.log({ error })
		})

	// if (errorServe.value) {
	// 	console.log(errorServe.value);
	// 	$toast.error(
	// 		errorServe.value.message,
	// 		{
	// 			position: 'top-right',
	// 			duration: 5000
	// 		})

	// 	store.commit('auth/SET_USER_ERROR_SERVE', false)
	// }
}


</script>

<style lang="scss" scoped>
.card-form {
	&__item-btn {
		margin-bottom: rem(16);
	}

	&__item-link {
		color: $light-gray-color;
		text-align: center;
		margin: rem(32) auto 0;

		a {
			color: $primary-color;
			text-decoration: underline;
		}
	}
}

.btn__border {
	width: 100%;
}
</style>