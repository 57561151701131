export default {
  getMovieData(state) {
    return state.movieData;
  },
  getCastCrews(state) {
    return state.castCrews;
  },
  getPosts(state) {
    return state.posts;
  },
  getPost(state) {
    return state.post;
  }
}