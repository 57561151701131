import { paymentPeriodPriceMap } from '@/constants/payment';

export default {
  setError(state, error) {
    state.error = error;
  },
  setPeriod(state, period) {
    state.period = period;
    state.amount = paymentPeriodPriceMap.get(period);
  },
  storeSubscription(state, subscription) {
    state.subscription = subscription;
  },
  setAllowedSlaServices(state, services) {
    state.allowedSlaServices = services;
  },
  setAllowedKnetServices(state, services) {
    state.allowedKnetServices = services;
  },
  setAllowedSlaSandbox(state, services) {
    state.allowedSlaSandbox = services;
  },
};
