export default {
  SET_USER_FIREBASE: (state, payload) => {
    state.userData.data = payload
    state.userData.isAuth = payload ? true : false
    // localStorage.setItem('emailVerified', true)
    localStorage.setItem('isAuth', true)
  },
  SET_USER_SERVE: (state, payload) => {
    state.userData.dataServe = payload
    state.userData.isAuth = payload ? true : false
    localStorage.setItem('isAuth', true)
    localStorage.setItem('userVanityUrl', payload.vanity_url)
    localStorage.setItem('userServeId', payload.id)
    localStorage.setItem('userIsPremium', payload.isPremium)
  },
  SET_USER_SERVE_TOKEN: (state, payload) => {
    state.userData.dataServeToken = payload
    localStorage.setItem('userServeToken', payload)
  },
  SET_USER_LOADING: (state, payload) => state.userData.loading = payload,
  SET_USER_LOGOUT: (state) => {
    state.userData.isAuth = false
    state.userData.dataFirebase = null
    state.userData.dataServe = null
    state.sessionExpIn = 0
  },
  SET_USER_ERROR_FIREBASE: (state, payload) => state.userData.errorFirebase = payload,
  SET_USER_ERROR_SERVE: (state, payload) => state.userData.errorServe = payload,
  SET_CHANGE_PASSWORD_ERROR: (state, payload) => state.userData.changePasswordError = payload,
  SET_USER_EMAIL_VERIFIED: (state, payload) => {
    state.userData.isVerified = payload
    localStorage.setItem('emailVerified', payload)
    localStorage.setItem('emailVerifiedTime', new Date().getDay())
  },
  SET_SIGN_IN_FORM_VISIBLE: (state, payload) => {
    state.signInFormVisible = payload
    localStorage.setItem('signInFormVisible', payload)
  },
  SET_SIGN_UP_FORM_VISIBLE(state, payload) {
    state.signUpFormVisible = payload
  },
  SET_SIGN_UP_POPUP_SACCESS_VISIBLE(state, payload) {
    state.signUpSaccessPopupVisible = payload
  },
  SET_RESET_PASSWORD_VISIBLE(state, payload) {
    state.resetPasswordVisible = payload
  },
  SET_CHECK_EMAIL_VISIBLE(state, payload) {
    state.checkEmailVisible = payload
  },
  SET_CREATE_NEW_PASSWORD_VISIBLE(state, payload) {
    state.createNewPasswordVisible = payload
  },
  SET_LOCAL_STORAGE_CLEAR: () => {
    localStorage.removeItem('emailVerified')
    localStorage.removeItem('emailVerifiedTime')
    localStorage.removeItem('isAuth')
    localStorage.removeItem('signInFormVisible')
    localStorage.removeItem('userServeToken')
    localStorage.removeItem('userServeId')
    localStorage.removeItem('userVanityUrl')
    localStorage.removeItem('sessionExpIn')
    localStorage.setItem('userIsPremium', false)
  },
  SET_SESSION_EXP: (state, time) => {
    state.userData.sessionExpIn = time;
    localStorage.setItem('sessionExpIn', time);
  },
  SET_RESET_PASSWORD: (state, payload) => {
    state.resetPassword = payload
  },
  setResetEmail: (state, email) => {
    state.resetEmail = email;
  },
};
