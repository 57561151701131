<template>
  <div class="verification">
    <div class="verification__header">
      <span class="verification__icon"><font-awesome-icon icon="circle-check" /></span>
      <div class="verification__title">
        <slot name="title"></slot>
      </div>
    </div>
    <div class="verification__desc">
      <slot name="desc"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailVerification',
  props: {
    icon: {
      type: String,
      default: 'check_circle',
    },
  },
};
</script>

<style lang="scss">
.verification {
  color: $light-gray-color;
  text-align: center;

  &__header {
    font-size: rem(24);
    line-height: math.div(32, 24);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: rem(10);
    border-bottom: 1px solid #4b4b4b;
    padding-bottom: rem(10);
    margin-bottom: rem(16);
  }

  &__title {
    width: 100%;
  }

  &__icon {
    font-size: rem(82);
    padding: rem(10) 0;
    color: $primary-color;
  }

  &__desc {
    line-height: math.div(22, 16);
  }
}
</style>
