<template>
  <form class="auth-sign-form">
    <div class="form__column">
      <UIInput
        name="fieldEmail"
        label="email-address"
        type="email"
        v-model:value="v$.emailAuth.$model"
        @blur="v$.emailAuth.$commit"
        :errors="v$.emailAuth.$errors" />
    </div>
    <div class="form__column">
      <UIInput
        name="fieldPassword"
        label="password"
        :type="passwordType"
        v-model:value="v$.passwordAuth.$model"
        :passwordBtn="true"
        @blur="v$.passwordAuth.$commit"
        :errors="v$.passwordAuth.$errors" />
    </div>
  </form>
  <div class="auth-sign__recover-password">
    <router-link to="#" @click="handleRecoverPassword">
      {{ $t('recover-password') }}
    </router-link>
  </div>
  <div class="auth-sign__button">
    <UIButton
      class="btn__border"
      :disabled="isSubmitDisabled"
      @click="handleSignIn">
      {{ $t('sign-in') }}
    </UIButton>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'vue-toast-notification';
import { required, email, minLength, helpers } from '@vuelidate/validators';
import UIButton from '@/components/ui/UIButton.vue';
import UIInput from '@/components/ui/UIInput.vue';

const store = useStore()
const $toast = useToast();
const { t } = useI18n()
const isErrorUserServe = computed(() => store.state.auth.userData.errorServe)

// * Validation fealds email and password
// * ------------------------------------
const emailAuth = ref('')
const passwordAuth = ref('')
const passwordType = ref('password')
const requiredNumberPassword = ref(6)
const rules = computed(() => ({
  emailAuth: {
    email: helpers.withMessage(t('enter-valid-email-address'), email),
    required: helpers.withMessage(t('field-required'), required),
  },
  passwordAuth: {
    required: helpers.withMessage(t('field-required'), required),
    minLength: helpers.withMessage(
      t('password-min-length'),
      minLength(requiredNumberPassword.value))
  }
}))

const v$ = useVuelidate(rules,
  { emailAuth, passwordAuth },
  { $rewardEarly: true })

// * Form processing
// * ------------------------------------
const isSubmitDisabled = ref(true)

watchEffect(() => {
  if (!v$.value.$error && v$.value.$dirty) {
    isSubmitDisabled.value = false
  } else {
    isSubmitDisabled.value = true
  }
})

const handleSignIn = async () => {
  if (v$.value.$invalid) {
    v$.value.$validate()
    return;
  }
  const payload = {
    email: emailAuth.value,
    password: passwordAuth.value,
  };
  await store.dispatch('auth/loginEmailAndPassword', payload)

  if (isErrorUserServe.value) {
    console.log(isErrorUserServe.value);
    $toast.error(
      isErrorUserServe.value.message,
      {
        position: 'top-right',
        duration: 5000
      })

    store.commit('auth/SET_USER_ERROR_SERVE', false)
  }
}

const handleRecoverPassword = () => {
  store.commit('auth/SET_SIGN_IN_FORM_VISIBLE', false)
  store.commit('auth/SET_RESET_PASSWORD_VISIBLE', true)
}

</script>

<style lang="scss" scoped>
.form__controle-focus {
  label {
    pointer-events: auto;
    top: rem(5);
    opacity: 1;
    font-size: 12px;
  }
}

.auth-sign {
  &__recover-password {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(32);

    a {
      color: $primary-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__button {
    margin-bottom: rem(16);

    .btn__border {
      width: 100%;
    }
  }
}
</style>