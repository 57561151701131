import { createI18n } from 'vue-i18n';
import { defaultLanguage } from '@/config'

function loadLocaleMessages() {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const messages = Object.assign(loadLocaleMessages())

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: defaultLanguage,
  allowComposition: false,
  fallbackLocale: defaultLanguage,
  messages,
});

export default i18n;
