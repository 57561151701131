<template>
	<UIModalLogo />
	<UIModalHeader htitle="create-new-password-title" hdesc="create-new-password-desc" />
	<div class="card-form__body">
		<form @submit.prevent class="form">
			<div class="form__column">
				<UIInput
					name="fieldPassword"
					label="password"
					:type="passwordType"
					v-model:value="v$.passwordAuth.$model"
					:passwordBtn="true"
					@blur="v$.passwordAuth.$commit"
					:errors="v$.passwordAuth.$errors" />
			</div>
			<div class="form__column">
				<UIInput
					name="fieldConfirmPassword"
					label="Confirm Password"
					:type="passwordType"
					v-model:value="v$.passwordAuth.$model"
					:passwordBtn="true"
					@blur="v$.passwordAuth.$commit"
					:errors="v$.passwordAuth.$errors" />
			</div>
			<div class="card-form__item-btn m__t--5">
				<UIButton
					class="btn__border"
					:disabled="isSubmitDisabled"
					style="width: 100%"
					@click="handleSignUp">
					{{ $t('create-new-password-btn') }}
				</UIButton>
			</div>
		</form>
	</div>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import UIModalLogo from '../ui/UIModalLogo.vue';
import UIModalHeader from '../ui/UIModalHeader.vue';
// import { useStore } from 'vuex';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength, helpers } from '@vuelidate/validators'
// import { useToast } from 'vue-toast-notification';

import UIButton from '@/components/ui/UIButton.vue';
import UIInput from '@/components/ui/UIInput.vue';

// const store = useStore()
const { t } = useI18n()
// const $toast = useToast();

// * Validation fealds email and password
// * ------------------------------------
const passwordAuth = ref('')
const passwordType = ref('password')
const minNumberPassword = ref(6)
const maxNumberPassword = ref(20)
const rules = computed(() => ({
	passwordAuth: {
		required: helpers.withMessage(t('field-required'), required),
		minLength: helpers.withMessage(
			t('password-min-length'),
			minLength(minNumberPassword.value)),
		maxLength: helpers.withMessage(
			t('password-max-length'),
			maxLength(maxNumberPassword.value)),
		containsUppercase: helpers.withMessage(
			t('password-uppercase'),
			(value) => /[A-Z]/.test(value)),
		containsLowercase: helpers.withMessage(
			t('password-lowercase'),
			(value) => /[a-z]/.test(value)),
		containsNumber: helpers.withMessage(
			t('password-number'),
			(value) => /[0-9]/.test(value)),
		containsSpecial: helpers.withMessage(
			t('password-special'),
			(value) => /[#?!@$%^&*-]/.test(value)),
	},
}))
// const authError = computed(() => store.state.auth.userData.error)


const v$ = useVuelidate(
	rules,
	{ passwordAuth },
	{ $rewardEarly: true },
)

// * Form processing
// * ------------------------------------
const isSubmitDisabled = ref(true)

watchEffect(() => {
	if (!v$.value.$error && v$.value.$dirty) {
		isSubmitDisabled.value = false
	} else {
		isSubmitDisabled.value = true
	}
})

const handleSignUp = () => {
	if (v$.value.$invalid) {
		v$.value.$touch();
		return;
	}

	// const authData = {
	// 	password: passwordAuth.value,
	// };

	// store.dispatch('auth/emailPasswordRegister', authData)
	// 	.then(() => {
	// 		if (String(authError.value).includes('auth/email-already-in-use')) {
	// 			$toast.error(t('signup-email-use'), {
	// 				position: 'top-right',
	// 				duration: 5000
	// 			});
	// 		} else if (authError.value) {
	// 			$toast.error(t('unexpected-errore'), {
	// 				position: 'top-right',
	// 				duration: 5000
	// 			});
	// 			console.log({ authError });
	// 		}
	// 	})
}


</script>

<style lang="scss" scoped>
.card-form {

	// .card-form__item-btn

	&__item-btn {
		margin-bottom: rem(32);
	}

	// .card-form__item-or

	&__item-or {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: rem(32);
		column-gap: rem(5);
	}
}

.or-style {

	// .or-style__left

	&__left,
	&__right {
		background-color: $light-gray-color;
		height: rem(1);
		width: 100%;
	}

	// .or-style__center

	&__center {
		color: $light-gray-color;
		font-weight: 500;
		font-size: rem(14);
		line-height: math.div(16, 14);
	}

	// .or-style__right

	&__right {}
}
</style>