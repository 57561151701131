import { useDeviceDetector } from '@/utils/useDeviceDetector';

export default {
  switchLanguage({ commit, dispatch }, { dir, lang }) {
    try {
      commit('SET_DIRECTION', dir);
      commit('SET_LANGUAGE', lang);
      dispatch('homePage/loadData');
    } catch (e) {
      console.error(e);
    }
  },
  getDeviceDetect({ commit }) {
    commit('SET_LOADING', true)
    try {
      const device = useDeviceDetector()
      commit('SET_DEVICE', device)
    } catch (error) {
      console.log({ error });
    }
    commit('SET_LOADING', false)
  }
};
