function useDeviceDetector() {
	const windows = find("windows");
	const macintosh = find("macintosh");
	const X11 = find("X11");
	const tablet = (/ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(navigator.userAgent.toLowerCase()));
	const mobile = (/iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/i.test(navigator.userAgent.toLowerCase()));

	let isDesctop = windows || macintosh || X11;
	let isTablet = tablet;
	let isMobile = mobile;

	window.addEventListener('resize', function () {
		isDesctop = windows || macintosh || X11;
		isTablet = (/ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(navigator.userAgent.toLowerCase()));
		isMobile = (/iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/i.test(navigator.userAgent.toLowerCase()));
	});

	return {
		isDesctop,
		isTablet,
		isMobile,
	}
}

const find = (needle) => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	return userAgent.indexOf(needle) !== -1;
}

export { useDeviceDetector }