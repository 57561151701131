import { algoliaId, algoliaKey } from '@/config';
import algoliasearch from 'algoliasearch/lite';
import useLocalStorage from '@/utils/useLocalStorage.js';
import app from '@/core/firebase';
import { getDatabase, ref, get, child } from "firebase/database";

// * state
const state = () => ({
  searchData: {
    loading: false,
    data: null,
    error: null,
  },
  watching: {
    loading: false,
    data: [],
    error: null,
  }
})

// * mutations 

const mutations = {
  SET_SEARCH_DATA: (state, payload) => {
    state.searchData.data = payload
  },
  SET_SEARCH_LOADING: (state, payload) => {
    state.searchData.loading = payload
  },
  SET_WATCHING_DATA: (state, payload) => state.watching.data = payload,
  SET_WATCHING_LOADING: (state, payload) => state.watching.loading = payload,
  SET_WATCHING_ERROR: (state, payload) => state.watching.error = payload,
}

// * actions

const actions = {
  async getSearch({ commit, rootState }) {
    commit('SET_PRELOAD', true, { root: true })
    commit('SET_SEARCH_LOADING', true)
    const LS = useLocalStorage()
    const isAuth = rootState.auth.userData.isAuth
    const isPremium = rootState.auth.userData.dataServe?.isPremium

    if (isAuth && isPremium) {
      const dbRef = ref(getDatabase(app))
      await get(child(dbRef, `watching/${LS.userServeId}/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userWatchingList = snapshot.val()
            // console.log('2. Get watching movie', watchingListFiltered);
            commit('SET_WATCHING_DATA', userWatchingList);
          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        });
    }
    const searchClient = algoliasearch(algoliaId, algoliaKey)
    // const index = searchClient.initIndex('Movies New');
    commit('SET_SEARCH_DATA', searchClient)
    commit('SET_SEARCH_LOADING', false)
    commit('SET_PRELOAD', false, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};