<template>
  <main class="">
    <div class="main__container">
      <UIModal
        @click="handleModal"
        :mobile="true">
        <div class="popup-content">
          <img src="@/assets/img/mobile_img_popup.png" alt="" />
          <h1 class="popup-content__title">{{ $t('mobilePopup.title') }}</h1>
          <p class="popup-content__desc">{{ $t('mobilePopup.desc') }}</p>
          <div class="popup-content__btn">
            <a href="https://apps.apple.com/us/app/mahatat-watch-tv-movies/id524552885">
              <img src="@/assets/img/Download_on_the_App_Store_Badge.png" alt="" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.telly">
              <img src="@/assets/img/Google_Play_Store_badge_EN.png" alt="" />
            </a>
          </div>
        </div>
      </UIModal>
    </div>
  </main>
</template>

<script setup>
import { useStore } from 'vuex';
import UIModal from '@/components/ui/UIModal.vue';

const store = useStore()

const handleModal = () => store.commit('SET_MOBILE_POPUP_VISIBLE', false)
</script>

<style lang="scss" scoped>
.popup-content {

  img {
    margin-bottom: rem(16);
  }

  // .popup-content__title

  &__title {
    font-weight: 700;
    font-size: rem(24);
    line-height: math.div(32, 24);
    text-align: center;
    margin-bottom: rem(16);
  }

  // .popup-content__desc

  &__desc {
    color: $light-gray-color;
    margin-bottom: rem(16);
  }

  // .popup-content__btn

  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: rem(16);
  }
}
</style>