<template>
	<div v-if="!loading && !userData?.premium_member" class="bunner-ads">
		<div ref="adSlot" :id="ads.slotId" class="bunner-ads__item"></div>
		<div class="bunner-ads__lable">
			{{ $t('advertisement') }}
		</div>
	</div>
</template>

<script setup>
import { computed, defineProps, ref, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore()
const loading = computed(() => store.state.auth.userData.loading)
const userData = computed(() => store.state.auth.userData.dataServe)
const adSlot = ref(null);
const props = defineProps({
	ads: {
		tyep: Object,
		required: true
	}
})



onMounted(() => {
	if (typeof window !== "undefined") {
		const { googletag } = window;



		googletag.cmd.push(() => {
			const slots = googletag.pubads().getSlots();
			const isSlotAdded = slots.some(slot => slot.getSlotElementId() === props.ads.slotId);
			if (!isSlotAdded) {
				const adMapping = googletag.sizeMapping();
				Object.keys(props.ads.mapping).forEach((breakpoint) => {
					adMapping.addSize([Number(breakpoint), 0], props.ads.mapping[breakpoint]);
				});
				const builtMapping = adMapping.build();

				googletag.defineSlot(props.ads.path, props.ads.sizes, props.ads.slotId)
					.defineSizeMapping(builtMapping)
					.addService(googletag.pubads());
				googletag.pubads().enableSingleRequest();
				googletag.enableServices();
				googletag.display(props.ads.slotId);
			} else {
				googletag.pubads().refresh()
			}
		});

	}
});

</script>

<style lang="scss" scoped>
.bunner-ads {
	display: flex;
	// display: none;
	align-items: center;
	justify-content: center;
	position: relative;
	flex-direction: column;

	&__item {
		background-color: $base-gray-color;
		text-align: center;
		width: auto;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	&__lable {
		width: auto;
		font-size: 12px;
	}

	&.small {
		.bunner-ads__item {
			min-height: rem(90);
		}
	}

	&.big {
		.bunner-ads__item {
			height: rem(250);
		}
	}
}
</style>