export default {
  mainData: {
    loading: false,
    data: null,
    error: null,
  },
  watching: {
    loading: false,
    data: [],
    error: null,
  }
}