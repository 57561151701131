import axios from 'axios';
import { backendUrl } from '@/config';
import useLocalStorage from '@/utils/useLocalStorage';
import app from '@/core/firebase';
import { getDatabase, ref, get, child } from "firebase/database";

export default {
  async loadData({ commit, rootState }) {
    commit('SET_PRELOAD', true, { root: true })
    commit('SET_LOADING_MD', true);
    const LS = useLocalStorage()
    const isAuth = rootState.auth.userData.isAuth
    const isPremium = rootState.auth.userData.dataServe?.isPremium

    if (isAuth && isPremium) {
      const dbRef = ref(getDatabase(app))
      await get(child(dbRef, `watching/${LS.userServeId}/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            commit('SET_WATCHING_DATA', snapshot.val());
          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        });
    }
    await axios.get(`${backendUrl}/premium_category/feed?slug=home&limit=10&hierarchy_level=3&normalize=true&user_id=${LS?.userServeId}&locale=${rootState.language}`)
      .then(({ data }) => {
        commit('SET_MAIN_DATA', data);
      })
      .catch(error => console.log('Main page content error - ', error));
    commit('SET_LOADING_MD', false);
    commit('SET_PRELOAD', false, { root: true })
  }
}