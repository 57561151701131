export default {
    getUserName(state) {
        const { displayName, email, phoneNumber } = state.userProfile.providerData
            .find(item => item.displayName || item.email || item.phoneNumber)
        return displayName || email || phoneNumber;
    },
    getUserDataServe(state) {
        return state.userData.dataServe
    }
};
