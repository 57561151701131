<template>
  <div class="lang">
    <div v-for="(languageName, index) in languageNames" :key="index" class="lang__pointer"
      :class="{ active: activeLang === languageName }" @click="onLanguageChange($event)">
      {{ languageName.toUpperCase() }}
    </div>
    <!-- <div class="lang__icon"></div> -->
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { languages } from '@/config';
import { switchLanguage } from '@/utils/useTranslate';

const route = useRoute()
const router = useRouter()
const languageNames = languages
const activeLang = ref(null);

watch(() => route.params.locale, () => activeLang.value = route.params.locale)

const onLanguageChange = async (event) => {
  const newLocale = event.target.innerText.toLowerCase()
  await switchLanguage(newLocale)
  try {
    await router.replace({ params: { locale: newLocale } })
  } catch (e) {
    console.log(e)
    router.push("/")
  }
}
</script>

<style lang="scss" scoped>
.lang {
  display: flex;
  align-items: center;
  column-gap: rem(15);

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &__pointer {
    color: $light-gray-color;
    cursor: pointer;

    &.active,
    &:hover {
      color: $base-color-font;
    }
  }

  &__icon {
    width: rem(21);
    background-image: url('~@/assets/img/world.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }
}

[dir="rtl"] {
  .lang {
    margin-left: 0;
    margin-right: rem(30);
  }
}
</style>
