<template>
	<div class="navbar__mobile-bottom">
		<div class="navbar-mobile__item-link">
			<HeaderMobileNav
				v-if="device.isMobile"
				@showSignIn="showSignIn(true)"
				@showSignUp="showSignUp(true)" />
			<span>Menu</span>
		</div>
		<!-- Search link component -->
		<div class="navbar-mobile__item-link">
			<HeaderSearchLink />
			<span>Search</span>
		</div>
		<div class="navbar-mobile__item-link">
			<router-link to="/watchlist">
				<font-awesome-icon icon="list-check" />
			</router-link>
			<span>Watchlist</span>
		</div>
		<!-- end Search link component -->
		<div class="navbar-mobile__item-link">
			<router-link to="/account/profile">
				<font-awesome-icon icon="user" />
			</router-link>
			<span>Account</span>
		</div>
	</div>
</template>

<script setup>

import { computed } from 'vue'
import { useStore } from 'vuex';
import HeaderMobileNav from '@/components/header/HeaderMobileNav.vue';
import HeaderSearchLink from '@/components//header/HeaderSearchLink.vue';

const store = useStore()
const device = computed(() => store.state.device)
const showSignUp = () => store.commit('auth/SET_SIGN_UP_FORM_VISIBLE', true)
const showSignIn = () => store.commit('auth/SET_SIGN_IN_FORM_VISIBLE', true)

</script>

<style lang="scss" scoped>
.navbar {

	&__mobile-bottom {
		background-color: #000;
		bottom: 0;
		height: rem(70);
		width: 100%;
		position: fixed;
		z-index: 9;

		display: flex;
		align-items: center;
		justify-content: space-around;

	}

	&-mobile__item-link {
		color: $light-gray-color !important;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		a {
			color: $light-gray-color !important;
			// padding: rem(8);
		}

		span {
			font-size: rem(10);
			line-height: math.div(15, 10);
		}
	}
}
</style>