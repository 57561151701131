import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	faXmark,
	faPlay,
	faMagnifyingGlass,
	faAngleDown,
	faStar,
	faShareNodes,
	faDownLong,
	faThumbsUp,
	faHeart,
	faArrowRightLong,
	faArrowLeftLong,
	faUserSlash,
	faEyeSlash,
	faAnglesRight,
	faUser,
	faCircleCheck,
	faBars,
	faPaperclip,
	faPlus,
	faCheck,
	faMinus,
	faExclamation,
	faInfo,
	faListCheck,
	faAngleRight,
	faEnvelope
} from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar, faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons'

library.add(
	faXmark,
	faPlay,
	faApple,
	faGooglePlay,
	faMagnifyingGlass,
	faAngleDown,
	faStar,
	farStar,
	faShareNodes,
	faDownLong,
	faThumbsUp,
	faHeart,
	faArrowRightLong,
	faArrowLeftLong,
	faUserSlash,
	faEyeSlash,
	faAnglesRight,
	faUser,
	faCircleCheck,
	faBars,
	faPaperclip,
	faPlus,
	faCheck,
	faMinus,
	faExclamation,
	faInfo,
	faListCheck,
	faAngleRight,
	farHeart,
	faEnvelope
);

export default FontAwesomeIcon;
