<template>
  <div class="modal modal__wrap">
    <div class="modal__container" :class="classes" @click.stop>
      <ButtonClose @click="closeModal" />
      <slot>Modal content</slot>
    </div>
  </div>
</template>

<script>
import ButtonClose from "./ButtonClose.vue";
export default {
  components: {
    ButtonClose,
  },
  props: {
    modalUser: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    },
    btnCloseNo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        'modal__user': this.modalUser,
        'modal__mobile': this.mobile,
        'btn__close-no': this.btnCloseNo
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('click');
    }
  },
}
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;

  &__full {
    [class*="__container"] {
      height: 85vh;
      width: 100%;
      position: relative;

      .modal__preloader {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1100;
        background-color: #000;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__wrap {
    background-color: $modal-wrap-bg;
    height: 100%;
    width: 100%;
  }

  &__container {
    background-color: $modal-container-bg;
    border-radius: rem(20);
    min-width: rem(250);
    padding: rem(40);
    position: relative;

    @include media-breakpoint-down(xs) {
      padding-left: rem(20);
      padding-right: rem(20);
    }
  }

  &__user {
    max-width: rem(500);
    width: 100%;

    @include media-breakpoint-down(xs) {
      border-radius: 0;
      height: calc(100vh);

    }
  }

  &__mobile {
    max-width: rem(327);
    padding: rem(56) rem(16) rem(16);
    width: 100%;
  }

  .btn__close-no {
    .btn__close {
      display: none;
    }
  }

  .btn__close {
    background-color: transparent;
    position: absolute;
    top: rem(15);
    right: rem(15);
    z-index: 9;
  }
}
</style>