<template>
  <div class="">
    <MainPreloader v-if="preload" />
    <HeaderMain />
    <div class="wrapper">
      <div class="main__wrapper">
        <router-view />
      </div>
      <FooterMain />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex'
import MainPreloader from '@/components/ui/MainPreloader.vue';
import HeaderMain from '@/components/header/HeaderMain.vue';
import FooterMain from '@/components/footer/FooterMain.vue';


const store = useStore()
const preload = computed(() => store.state.preload)
store.dispatch('auth/isUser')
store.dispatch('getDeviceDetect')

onBeforeMount(() => {
  window.googletag.cmd.push(() => {
    window.googletag.destroySlots();
  });
})

</script>

<style lang="scss" scoped>
.wrapper {
  background-color: $main-bg;
}

html[dir="rtl"] .general-glider-prev {
  transform: rotateY(180deg) translateX(-80px);
}

html[dir="rtl"] .VueCarousel-inner {
  direction: ltr;
}
</style>
