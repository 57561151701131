import axios from 'axios';
import { backendUrl } from '@/config';

export default {
  async loadCastcrewData({ commit, rootState }) {
    commit('SET_PRELOAD', true, { root: true })
    commit('SET_CASTCREW_LOADING', true)
    // const dataLang = rootState.language;
    // const lang = dataLang === 'en' ? 'en' : 'ar';
    await axios.get(`${backendUrl}/castcrew/?locale=${rootState.language}`)
      .then(({ data }) => {
        commit('SET_CASTCREW_DATA', data.result);
      })
      .catch(error => {
        console.log('Load castcrew data error - ', error);
      })
    commit('SET_CASTCREW_LOADING', false)
    commit('SET_PRELOAD', false, { root: true })
  }
}