<template>
  <div class="header-mobile">
    <button class="header-mobile__btn" @click="handleClick">
      <font-awesome-icon icon="bars" />
    </button>
    <div :class="['header-mobile__dropdown', { 'show': dropdownVisible }]">
      <div class="header-mobile__dropdown-header">
        <UIButton v-if="!isAuth" @click="handleSignIn">
          {{ $t('form.signIn') }}
        </UIButton>
        <UIButton v-if="!isAuth" @click="handleSignUp">
          {{ $t('form.signUp') }}
        </UIButton>
        <ButtonClose @click="handleClose" />
      </div>
      <div class="header-mobile__dropdown-body">
        <ul class="header-mobile__dropdown-nav">
          <li>
            <router-link
              class="navbar__link-menu"
              :to="i18nRoute({ name: 'home page' })"
              @click="handleClose">
              {{ $t('headerMain.linkMenu1') }}
            </router-link>
          </li>
          <li>
            <router-link
              class="navbar__link-menu"
              :to="i18nRoute({ name: 'media page', params: { id: 'movies' } })"
              @click="handleClose">
              {{ $t('headerMain.linkMenu2') }}
            </router-link>
          </li>
          <li>
            <router-link
              class="navbar__link-menu"
              :to="i18nRoute({ name: 'media page', params: { id: 'series' } })"
              @click="handleClose">
              {{ $t('headerMain.linkMenu3') }}
            </router-link>
          </li>
          <li>
            <router-link
              class="navbar__link-menu"
              :to="i18nRoute({ name: 'feed page', params: { slug: 'new-on-mahatat' } })"
              @click="handleClose">
              {{ $t('headerMain.linkMenu4') }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, computed } from 'vue'
import { useStore } from 'vuex';
import UIButton from '@/components/ui/UIButton.vue';
import ButtonClose from "@/components/ui/ButtonClose.vue";
import { i18nRoute } from '@/utils/useTranslate';

const emit = defineEmits(['showSignIn', 'showSignUp'])
const state = useStore()
const isAuth = computed(() => state.state.auth.userData.isAuth)
const dropdownVisible = ref(false)
const handleClick = () => dropdownVisible.value = true;
const handleClose = () => dropdownVisible.value = false;
const handleSignIn = () => {
  handleClose()
  emit('showSignIn')
}
const handleSignUp = () => {
  handleClose()
  emit('showSignUp')
}
</script>

<style lang="scss" scoped>
@mixin transition-ease {
  transition: all 0.3s ease-in-out;
}

.header-mobile {


  // .header-mobile__btn

  &__btn {
    font-size: rem(18);
    // padding: rem(8);
  }

  // .header-mobile__dropdown

  &__dropdown {
    background-color: $main-bg;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 99;
    opacity: 1;
    visibility: hidden;
    transform: translateX(-100%);
    @include transition-ease;

    &.show {
      visibility: visible;
      transform: translateX(0);
    }

    // .header-mobile__header

    &-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: rem(10);
      padding: rem(25) rem(20);
      border-bottom: rem(1) solid $base-gray-color;
    }

    // .header-mobile__body

    &-body {}

    &-nav {
      li {
        border-bottom: rem(1) solid $base-gray-color;
      }

      a {
        color: $light-gray-color;
        font-weight: 700;
        font-size: rem(16);
        line-height: math.div(28, 22);
        display: block;
        padding: rem(10) rem(20);
        widows: 100%;
      }
    }
  }

}
</style>