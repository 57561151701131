import { paymentPeriodPriceMap } from '@/constants/payment';

const DEFAULT_PERIOD = 'monthly';

export default {
  error: null,
  period: DEFAULT_PERIOD,
  amount: paymentPeriodPriceMap.get(DEFAULT_PERIOD),
  subscription: null,
  allowedSlaServices: [],
  allowedKnetServices: false,
  allowedSlaSandbox: false,
};
