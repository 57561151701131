function getPreLoginAuth() {
  const sessionExpIn = localStorage.getItem('sessionExpIn')

  return {
    sessionExpIn,
  };
}

export default {
  userData: {
    loading: false,
    dataFirebase: null,
    dataServe: null,
    dataServeToken: null,
    isAuth: false,
    errorServe: false,
    errorFirebase: false,
    isVerified: true,
    sessionExpIn: null,
    changePasswordError: false,
    resetPassword: [],
    ...getPreLoginAuth(),
  },
  resetEmail: null,
  toasterVisible: false,
  signInFormVisible: false,
  signUpFormVisible: false,
  signUpSaccessPopupVisible: false,
  resetPasswordVisible: false,
  checkEmailVisible: false,
  createNewPasswordVisible: false,
};
