const backendUrl = process.env.VUE_APP_API_ENDPOINT;
const videoCDNUrl = 'https://cdn.jwplayer.com/v2';
const algoliaId = process.env.VUE_APP_ALGOLIA_ID;
const algoliaKey = process.env.VUE_APP_ALGOLIA_KEY;

const defaultDirection = 'ltr';
const defaultLanguage = 'en';
const fullbackLanguage = 'ar';
const languages = ['ar', 'en'];

export {
  backendUrl,
  videoCDNUrl,
  defaultDirection,
  defaultLanguage,
  fullbackLanguage,
  languages,
  algoliaId,
  algoliaKey,
};
