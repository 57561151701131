<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__row">
        <div class="footer__col">
          <div class="footer__brand">
            <router-link :to="i18nRoute({ name: 'home page' })" class="footer__link">
              <img class="footer__brand-img" src="~@/assets/img/logo.svg" />
            </router-link>
          </div>
          <div class="footer__desc">
            {{ $t('footerMane.Desc') }}
          </div>
        </div>
        <div class="footer__col">
          <ul class="footer-nav-link">
            <li class="footer-nav-link__item">
              <router-link :to="i18nRoute({ name: 'watchlist page' })">
                {{ $t('footerMane.Watchlist') }}
              </router-link>
            </li>
            <li class="footer-nav-link__item">
              <router-link :to="i18nRoute({ name: 'categories page' })">{{ $t('footerMane.Categories') }}</router-link>
            </li>
            <li class="footer-nav-link__item">
              <a href="#" @click="handleAccount()"> {{ $t('footerMane.Account') }}</a>
            </li>
          </ul>
        </div>
        <div class="footer__col">
          <ul class="footer-nav-link">
            <li class="footer-nav-link__item">
              <router-link :to="i18nRoute({ name: 'support page' })">{{ $t('footerMane.Support') }}</router-link>
            </li>
            <li class="footer-nav-link__item">
              <router-link :to="i18nRoute({ name: 'staticPage', params: { id: 'faq', } })">{{
                $t('footerMane.Faq')
              }}</router-link>
            </li>
            <li class="footer-nav-link__item">
              <router-link :to="i18nRoute({ name: 'staticPage', params: { id: 'cookie-policy', } })">
                {{ $t('footerMane.CookiePolicy') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="footer__col">
          <ul class="footer-nav-link">

            <li class="footer-nav-link__item">
              <router-link :to="i18nRoute({ name: 'staticPage', params: { id: 'terms-and-conditions', } })">
                {{ $t('footerMane.TermsAndConditions') }}
              </router-link>
            </li>
            <li class="footer-nav-link__item">
              <router-link :to="i18nRoute({ name: 'staticPage', params: { id: 'privacy-policy', } })">
                {{ $t('footerMane.PrivacyPolicy') }}
              </router-link>
            </li>
            <li class="footer-nav-link__item">
              <router-link :to="i18nRoute({ name: 'staticPage', params: { id: 'eula', } })">
                {{ $t('footerMane.EULA') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="footer__col">
          <div class="footer-social__items">
            <div class="footer-social__title">{{ $t('footerMane.FollowUs') }}:</div>
            <a href="https://www.facebook.com/MahatatApp/" target="_blank" rel="noopener" class="footer-social__link">
              <img src="~@/assets/img/facebook.svg">
              <span>{{ $t('footerMane.Facebook') }}</span>
            </a>
            <a href="https://instagram.com/mahatatapp" target="_blank" rel="noopener" class="footer-social__link">
              <img src="~@/assets/img/instagram.svg">
              <span>{{ $t('footerMane.Instagram') }}</span>
            </a>
            <a href="https://www.youtube.com/channel/UC42poAF7mnNTN_zZEQJf3jw" target="_blank" rel="noopener"
              class="footer-social__link">
              <img src="~@/assets/img/youtube.svg">
              <span>{{ $t('footerMane.Youtube') }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="copyright">
        <span class="copyright__text">{{ $t('footerMane.Copyright') }}</span>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { i18nRoute } from '@/utils/useTranslate';

const router = useRouter()
const store = useStore()
const isAuth = computed(() => store.state.auth.userData.isAuth)
const showSignIn = () => store.commit('auth/SET_SIGN_IN_FORM_VISIBLE', true)

const handleAccount = () => {
  isAuth.value ? router.push(i18nRoute({ name: 'profile' })) : showSignIn()
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: $footer-bg;
  padding-bottom: rem(16);

  @include media-breakpoint-down(xs) {
    display: none;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    column-gap: rem(100);
    justify-content: space-between;

    padding-top: rem(40);
    padding-bottom: rem(40);

    @include media-breakpoint-down(lg) {
      column-gap: rem(30);
    }

    @include media-breakpoint-down(sm) {
      align-items: center;
      flex-direction: column;
      row-gap: rem(30);
    }
  }

  &__col {

    // max-width: rem(195);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      max-width: rem(225);
      display: flex;
      flex: 5 1 0%;
    }
  }

  &__brand {
    margin-bottom: rem(20);

    @include media-breakpoint-down(xs) {
      display: none;
    }

    &-img {
      width: rem(190);
    }
  }

  &__desc {
    color: $footer-color-font;
    font-size: rem(14);
    font-weight: 400;
    line-height: 150%;
    max-width: rem(206);

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &-nav-link {
    display: flex;
    flex-direction: column;
    row-gap: rem(30);

    @include media-breakpoint-down(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: rem(30);
      row-gap: rem(20);
      width: 100%;
    }

    &__item {
      a {
        color: $footer-color-font;
        font-size: rem(16);
        font-weight: 500;
        line-height: math.div(24, 16);

        &:hover {
          text-decoration: underline;
          opacity: .8;
        }

        @include media-breakpoint-down(sm) {
          font-size: rem(16);
          line-height: math.div(24, 16);
        }
      }
    }
  }

  &-social {
    &__items {
      display: flex;
      flex-direction: column;
      row-gap: rem(30);

      @include media-breakpoint-down(sm) {
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: rem(30);
      }
    }

    &__title {
      font-size: rem(16);
      font-weight: 500;
      line-height: math.div(24, 16);

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      column-gap: rem(15);

      img {
        height: rem(24);
        width: rem(24);

        @include media-breakpoint-down(sm) {
          height: rem(32);
          width: rem(32);
        }
      }

      span {
        color: $primary-color;
        font-size: rem(16);
        font-weight: 500;
        line-height: math.div(24, 16);

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
}


.copyright {
  border-top: rem(1) solid $footer-copyright-border-color;
  padding: rem(16) 0;

  &__text {

    font-weight: 500;
    font-size: rem(14);
    line-height: math.div(16, 14);
    color: $footer-color-font;
  }
}
</style>
