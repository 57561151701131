import axios from 'axios';
import { backendUrl } from '@/config';
import runWithRefreshHook from '../../refresh-action-hook';
import useLocalStorage from '@/utils/useLocalStorage';

export default {
  createCreditCardPayment: runWithRefreshHook(({ state }, paymentToken) => {
    return axios.post(`${backendUrl}/payment/create/creditcard`, {
      data: {
        paymentToken,
        period: state.period,
      },
    });
  }),
  createSlaSubscription: runWithRefreshHook(async ({ state }, payload) => {
    try {
      return await axios.post(`${backendUrl}/payment/create/sla`, {
        data: {
          ...payload,
          period: state.period,
        },
      });
    } catch (e) {
      const { message } = e.response.data;
      return { error: true, message };
    }
  }),
  getSubscription: runWithRefreshHook(async ({ commit }) => {
    // const { data: { result } } = await axios.get(`${backendUrl}/subscription`);
    await axios.get(`${backendUrl}/subscription`)
      .then(({ data }) => {
        commit('setPeriod', data.result.subscription.period)
        commit('storeSubscription', data.result.subscription);
        commit('setAllowedSlaServices', data.result.allowedSlaServices);
        commit('setAllowedSlaSandbox', data.result.allowedSlaSandbox);
        commit('setAllowedKnetServices', data.result.allowedKnetServices);
      })
      .catch(error => console.log({ error }))
  }),
  cancelSubscription: runWithRefreshHook(async ({ commit }) => {
    const LS = useLocalStorage()

    const { data: { result } } = await axios.post(`${backendUrl}/subscription/cancel?token=${LS.userServeToken}`);
    commit('storeSubscription', result.subscription);
  }),
  getKnetPaymentUrl: runWithRefreshHook(async (_, { amt, period }) => {
    return axios.get(`${backendUrl}/payment/knet?amt=${amt}&period=${period}`);
  }),
  getSlaPaymentUrl: runWithRefreshHook(async (_, { service, msisdn }) => {
    return axios.get(`${backendUrl}/payment/sla`, { params: { service, msisdn } });
  }),
  createKNETPaymen: runWithRefreshHook(async (_, payload) => {
    try {
      return await axios.post(`${backendUrl}/payment/create/knet`, {
        data: {
          payload,
        },
      });
    } catch (e) {
      const { message } = e.response.data;
      return { error: true, message };
    }
  }),
};
