import { languages, defaultLanguage } from '@/config'
import useLocalStorage from './useLocalStorage'
import store from '@/store';
import i18n from '@/plugins/i18n';

const LS = useLocalStorage()

const isLocaleSupported = (locale) => {
	return languages.includes(locale)
}

const getUserLocale = () => {
	const locale = window.navigator.language ||
		window.navigator.userLanguage ||
		defaultLanguage

	return {
		locale: locale,
		localeNoRegion: locale.split('-')[0]
	}
}

const getPersistedLocale = () => {
	const persistedLocale = LS.userLocale

	if (isLocaleSupported(persistedLocale)) {
		return persistedLocale
	} else {
		return null
	}
}

const guessDefaultLocale = () => {
	const userPersistedLocale = getPersistedLocale()
	if (userPersistedLocale) {
		return userPersistedLocale
	}
	const userPreferredLocale = getUserLocale()
	if (isLocaleSupported(userPreferredLocale.locale)) {
		return userPreferredLocale.locale
	}
	if (isLocaleSupported(userPreferredLocale.localeNoRegion)) {
		return userPreferredLocale.localeNoRegion
	}

	return defaultLanguage
}

// const routeMiddleware = async (to, _from, next) => {
// 	const paramLocale = to.params.locale
// 	if (!isLocaleSupported(paramLocale)) {
// 		return next(guessDefaultLocale())
// 	}
// 	await switchLanguage(paramLocale)

// 	return next()
// }
const supportedLocales = () => {
	return languages
}
const currentLocale = (newLocale) => {
	i18n.global.locale.value = newLocale
}
const switchLanguage = async (newLocale) => {
	currentLocale(newLocale)
	const direction = newLocale === 'ar' ? 'rtl' : 'ltr';
	store.dispatch('switchLanguage', {
		dir: direction,
		lang: newLocale,
	});
	// document.querySelector("html").setAttribute("lang", newLocale)
}

const i18nRoute = (to) => {
	return {
		...to,
		params: {
			locale: store.state.language,
			...to.params
		}
	}
}

export {
	// routeMiddleware,
	supportedLocales,
	currentLocale,
	switchLanguage,
	guessDefaultLocale,
	isLocaleSupported,
	i18nRoute
}