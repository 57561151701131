import axios from 'axios';
import { backendUrl } from '@/config';
import useLocalStorage from '@/utils/useLocalStorage';
import app from '@/core/firebase';
import { getDatabase, ref, get, child } from "firebase/database";

// * state
const state = () => ({
  mediaData: {
    loading: false,
    data: null,
    error: null,
  },
  watching: {
    loading: false,
    data: {},
    error: null,
  }
})

// * mutations 

const mutations = {
  SET_MEDIA_DATA: (state, payload) => state.mediaData.data = payload,
  SET_MEDIA_LOADING: (state, payload) => state.mediaData.loading = payload,
  SET_MEDIA_ERROR: (state, payload) => state.mediaData.error = payload,
  SET_WATCHING_DATA: (state, payload) => state.watching.data = payload,
  SET_WATCHING_LOADING: (state, payload) => state.watching.loading = payload,
  SET_WATCHING_ERROR: (state, payload) => state.watching.error = payload,
}

// * actions

const actions = {
  async getMediaData({ commit, rootState }, payload) {
    commit('SET_MEDIA_LOADING', true)
    const LS = useLocalStorage()
    const isAuth = rootState.auth.userData.isAuth
    const isPremium = rootState.auth.userData.dataServe?.isPremium

    if (isAuth && isPremium) {
      const dbRef = ref(getDatabase(app))
      await get(child(dbRef, `watching/${LS.userServeId}/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            commit('SET_WATCHING_DATA', snapshot.val());
          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        });
    }

    await axios.get(`${backendUrl}/media/list?content_type=${payload}&locale=${rootState.language}`)
      .then(({ data }) => {
        const categories = new Set();
        const mediaByCategories = {};
        data.result.map(media => media.mediaGroups.forEach(g => {
          if (categories.has(g.slug)) {
            if (mediaByCategories[g.slug].filter(e => e.id === media.id).length === 0) {
              mediaByCategories[g.slug].push({ category: g.title, ...media })
            }
          } else {
            categories.add(g.slug)
            mediaByCategories[g.slug] = [{ category: g.title, ...media }];
          }
        }));

        commit('SET_MEDIA_DATA', Object.values(mediaByCategories))
      })
      .catch(error => console.log('Watchlist page content error - ', error));
    commit('SET_MEDIA_LOADING', false)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
