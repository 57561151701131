<template>
  <div class="card-form">
    <UIModalLogo />
    <UIModalHeader htitle="sign-up" hdesc="join-and-get-access" />
    <div class="card-form__body">
      <AuthSignUpEmailForm v-if="!isEmailVerificationTextVisible" />
    </div>
    <div class="card-form__item-link" v-if="!isEmailVerificationTextVisible">
      {{ $t('sign-up-account') }}
      <router-link to="#" @click="handleSignIn">
        {{ $t('form.signIn') }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import UIModalLogo from '../ui/UIModalLogo.vue';
import UIModalHeader from '../ui/UIModalHeader.vue';
import AuthSignUpEmailForm from './AuthSignUpEmailForm.vue';

const emit = defineEmits(['clickSignIn'])
const handleSignIn = () => emit('clickSignIn')
const isEmailVerificationTextVisible = ref(false)

</script>

<style lang="scss" scoped>
.card-form {
  &__item-btn {
    margin-bottom: rem(16);
  }

  &__item-link {
    color: $light-gray-color;
    text-align: center;

    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}
</style>