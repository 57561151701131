import axios from 'axios';
import { backendUrl } from '@/config';
import useLocalStorage from '@/utils/useLocalStorage';
import app from '@/core/firebase';
import { getDatabase, ref, get, child } from "firebase/database";

// * state
const state = () => ({
  categoriesData: {
    loading: false,
    data: null,
    error: null,
  },
  categoryData: {
    loading: false,
    data: null,
    error: null,
  },
  watching: {
    loading: false,
    data: [],
    error: null,
  }
})

// * mutations 

const mutations = {
  SET_CATEGORIES_DATA: (state, payload) => {
    state.categoriesData.data = payload
  },
  SET_CATEGORIES_LOADING: (state, payload) => {
    state.categoriesData.loading = payload
  },
  SET_CATEGORY_DATA: (state, payload) => {
    state.categoryData.data = payload
  },
  SET_CATEGORY_LOADING: (state, payload) => {
    state.categoryData.loading = payload
  },
  SET_WATCHING_DATA: (state, payload) => state.watching.data = payload,
  SET_WATCHING_LOADING: (state, payload) => state.watching.loading = payload,
  SET_WATCHING_ERROR: (state, payload) => state.watching.error = payload,
}

// * actions

const actions = {
  async loadCategoriesData({ commit, rootState }) {
    commit('SET_PRELOAD', true, { root: true })
    commit('SET_CATEGORIES_LOADING', true)
    await axios.get(`${backendUrl}/premium_category/feed?hierarchy_level=1&normalize=true&locale=${rootState.language}`)
      .then(({ data }) => {
        commit('SET_CATEGORIES_DATA', data)
        commit('SET_CATEGORIES_LOADING', false)
      })
      .catch(error => console.log('Main page content error - ', error));
    commit('SET_PRELOAD', false, { root: true })
  },
  async loadCategoryData({ commit, rootState }, payload) {
    commit('SET_PRELOAD', true, { root: true })
    commit('SET_CATEGORY_LOADING', true)
    const LS = useLocalStorage()
    const isAuth = rootState.auth.userData.isAuth
    const isPremium = rootState.auth.userData.dataServe?.isPremium

    if (isAuth && isPremium) {
      const dbRef = ref(getDatabase(app))
      await get(child(dbRef, `watching/${LS.userServeId}/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userWatchingList = snapshot.val()
            commit('SET_WATCHING_DATA', userWatchingList);
          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        });
    }

    await axios.get(`${backendUrl}/premium_category/feed?slug=${payload}&hierarchy_level=1&normalize=true&user_id=${LS.userServeId}&locale=${rootState.language}`)
      .then(({ data }) => {
        commit('SET_CATEGORY_DATA', data)
        commit('SET_CATEGORY_LOADING', false)
      })
      .catch(error => console.log('Main page content error - ', error));
    commit('SET_PRELOAD', false, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};