<template>
  <button @click="onClick" class="btn__close">
    <span class="icon__close"></span>
  </button>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
}
</script>

<style lang="scss" scoped>
.btn__close {
  height: rem(30);
  width: rem(30);
  min-width: auto;
  padding: 0;
  opacity: .3;

  @extend %flex__content--center;

  &:hover {
    opacity: 1;
  }

}
</style>