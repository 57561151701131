
function useLocalStorage() {
  return {
    isAuth: localStorage.getItem('isAuth'),
    emailVerified: localStorage.getItem('emailVerified'),
    emailVerifiedTime: localStorage.getItem('emailVerifiedTime'),
    signInFormVisible: localStorage.getItem('signInFormVisible'),
    userServeToken: localStorage.getItem('userServeToken'),
    userFingerprint: localStorage.getItem('fingerprint'),
    userVanityUrl: localStorage.getItem('userVanityUrl'),
    userServeId: localStorage.getItem('userServeId'),
    userIsPremium: localStorage.getItem('userIsPremium'),
    userLocale: localStorage.getItem('userLocale'),
  }
}

export default useLocalStorage