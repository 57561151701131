export default {
  SET_LOADING_MD: (state, payload) => state.movieData.loading = payload,
  SET_MOVIE_DATA: (state, payload) => state.movieData.data = payload,
  SET_TRAILERS: (state, payload) => state.trailers = payload,
  SET_VIDEO_CURRENT_PLAYLIST: (state, payload) => state.currentVideoData.data = payload,
  SET_LOADING_VCP: (state, payload) => state.currentVideoData.loading = payload,
  SET_TOGGLE_MODAL_VIDEO_PLAYER: (state, payload) => state.modalToggleVideoPlayer = payload,
  SET_SEARCH_RESULT: (state, payload) => state.searchResult = payload,
  SET_WATCHING_DATA: (state, payload) => state.watching.data = payload,
  SET_WATCHING_LOADING: (state, payload) => state.watching.loading = payload,
  SET_WATCHING_ERROR: (state, payload) => state.watching.error = payload,
  SET_MOVIE_ID: (state, payload) => state.movieData.id = payload
}