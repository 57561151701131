export default {
  movieData: {
    id: null,
    loading: false,
    data: null,
    error: null,
  },
  currentVideoData: {
    loading: false,
    data: null,
    error: null,
  },
  searchResult: {},
  watching: {
    loading: false,
    data: [],
    error: null,
  }
}