import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router/';
import store from '@/store';
import i18n from '@/plugins/i18n';
import { useI18n } from 'vue-i18n';
import { defaultLanguage } from '@/config';
import FontAwesomeIcon from '@/plugins/fa-icons';

import InstantSearch from 'vue-instantsearch/vue3/es';
import ToastPlugin from 'vue-toast-notification';
import VueScreen from 'vue-screen';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'maz-ui/css/main.css';
import '@/assets/css/maz-style.css';

const app = createApp(App, {
  setup() {
    const { t } = useI18n();
    return { t };
  }
});

app.config.locale = defaultLanguage;
app.use(VueSweetalert2);
app.use(ToastPlugin);
app.use(i18n);
app.use(store);
app.use(router);
app.use(InstantSearch);
app.use(VueScreen, 'tailwind')
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');

